import { useState, useEffect, useRef } from 'react';
import { Position, Handle, useReactFlow } from 'reactflow';
import { useOutletContext } from 'react-router-dom';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import styled from '@mui/material/styles/styled';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useLanguage } from 'src/context/LanguageContext';
import useConnectable from './useConnectable';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import VideoCard from '../VideoLibrary/VideoCard';
import SimpleDivider from '../../common/SimpleDivider';
import SimpleSlider from '../../common/SimpleSlider';
import { labelB, labelSpanDescription } from '../../common/SharedStylingSettings';
import getTheme from '../../common/SplitButton/SplitButtonThemes';
import HandleToolTip from './HandleToolTip';
import { packageVariant } from '../../../utils/utils';

const cardHeight = 650;
const cardWidth = cardHeight * 0.516;
function VideoFunnelVideoNode(props) {
  const { data, isConnectable, id } = props;
  const isDragging = data?.isDragging ? data.isDragging : false;

  const [nodeData, setNodeData] = useState(null);

  const lang = useLanguage();
  const { basicOrgData } = useOutletContext();

  const isFreemium = basicOrgData?.premiumFeatures?.packageVariant === packageVariant.freemium;

  const freemiumVideoFunnelLimit =
    isFreemium && basicOrgData?.premiumFeatures?.freemiumFeatures?.videoFunnel;

  const [videoTitle, setVideoTitle] = useState(data?.videoTitle ?? '');
  const videoTitleInputRef = useRef(null);

  const [handleATitle, setHandleATitle] = useState(data?.handleATitle ?? '');
  const aTitleInputRef = useRef(null);
  const [handleBTitle, setHandleBTitle] = useState(data?.handleBTitle ?? '');
  const bTitleInputRef = useRef(null);
  const [handleCTitle, setHandleCTitle] = useState(data?.handleCTitle ?? '');
  const cTitleInputRef = useRef(null);
  const [handleDTitle, setHandleDTitle] = useState(data?.handleDTitle ?? '');
  const dTitleInputRef = useRef(null);

  const [videoTitleValid, setVideoTitleValid] = useState(true);
  const [handleATitleValid, setHandleATitleValid] = useState(true);
  const [handleBTitleValid, setHandleBTitleValid] = useState(true);
  const [handleCTitleValid, setHandleCTitleValid] = useState(true);
  const [handleDTitleValid, setHandleDTitleValid] = useState(true);

  const hasTargetConnections = useConnectable({ id: data?.id, direction: 'target' });
  const hasSourceConnectionHandelA = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'a',
  });
  const hasSourceConnectionHandelB = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'b',
  });
  const hasSourceConnectionHandelC = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'c',
  });
  const hasSourceConnectionHandelD = useConnectable({
    id: data?.id,
    direction: 'source',
    sourceHandle: 'd',
  });

  const [videoIsLandscape, setVideoIsLandscape] = useState(false);
  useEffect(() => {
    data.videoIsLandscape = videoIsLandscape;
  }, [videoIsLandscape]);
  const [videoDuration, setVideoDuration] = useState(null);

  useEffect(() => {
    data.videoDuration = videoDuration;
  }, [videoDuration]);

  const ctaLabelMaxLength = videoIsLandscape ? 50 : 28;
  const videoTitleMaxLength = 65;

  const [showSettings, setShowSettings] = useState(data.showSettings);
  const [ctaTiming, setCtaTiming] = useState({
    start: data?.ctaTiming?.start >= 0 ? data?.ctaTiming?.start : null,
    end: data?.ctaTiming?.end ? data?.ctaTiming?.end : null,
  });

  useEffect(() => {
    data.showSettings = showSettings;
  }, [showSettings]);

  useEffect(() => {
    data.ctaTiming = ctaTiming;
  }, [ctaTiming]);
  const { getEdges, getNodes, setNodes } = useReactFlow();

  const { videoCard } = data;

  const hasLeastOneSourceConnections =
    [
      hasSourceConnectionHandelA,
      hasSourceConnectionHandelB,
      hasSourceConnectionHandelC,
      hasSourceConnectionHandelD,
    ]?.filter(Boolean)?.length >= 1;

  const hasAtLeastOneVideoNodes = () => {
    return (
      getEdges()?.filter(
        (edge) =>
          edge.source?.toString() === data?.id?.toString() &&
          getNodes()?.find(
            (node) =>
              node.id?.toString() === edge.target?.toString() &&
              node.type === 'videoFunnelVideoNode',
          ),
      )?.length >= 1
    );
  };

  const hasEndScreenNode = () => {
    return (
      getEdges()?.filter(
        (edge) =>
          edge.source?.toString() === data?.id?.toString() &&
          getNodes()?.find(
            (node) =>
              node.id?.toString() === edge.target?.toString() &&
              node.type === 'videoFunnelEndScreenNode',
          ),
      )?.length > 0
    );
  };

  const getEndScreenNode = (handel) => {
    const edge = getEdges()?.find(
      (edge) => edge?.sourceHandle === handel && edge?.source?.toString() === data?.id?.toString(),
    );
    if (!edge) {
      return null;
    }
    return getNodes()?.find(
      (node) =>
        node.id?.toString() === edge.target?.toString() && node.type === 'videoFunnelEndScreenNode',
    );
  };

  const handleHasEndScreenNode = (handel) => {
    return (
      getEdges()?.filter(
        (edge) =>
          edge?.sourceHandle === handel &&
          edge?.source?.toString() === data?.id?.toString() &&
          getNodes()?.find(
            (node) =>
              node.id?.toString() === edge.target?.toString() &&
              node.type === 'videoFunnelEndScreenNode',
          ),
      )?.length > 0
    );
  };

  useEffect(() => {
    if (hasAtLeastOneVideoNodes() || hasEndScreenNode()) {
      setShowSettings(true);
    }
  }, [hasAtLeastOneVideoNodes(), hasEndScreenNode()]);

  useEffect(() => {
    if (data?.shouldCheckConnections) {
      if (hasAtLeastOneVideoNodes() && !showSettings) {
        setShowSettings(true);
      }
      data.shouldCheckConnections = false;
    } else if (data?.showSettings) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [data]);

  useEffect(() => {
    setNodeData(data);
  }, []);

  useEffect(() => {
    if (nodeData?.id && !hasSourceConnectionHandelA && handleATitle?.length > 0) {
      delete data.handleATitle;
      validateTextLength('', ctaLabelMaxLength, 'setHandleATitle');
    }
  }, [hasSourceConnectionHandelA]);

  useEffect(() => {
    if (nodeData?.id && !hasSourceConnectionHandelB && handleBTitle?.length > 0) {
      delete data.handleBTitle;
      validateTextLength('', ctaLabelMaxLength, 'setHandleBTitle');
    }
  }, [hasSourceConnectionHandelB]);

  useEffect(() => {
    if (nodeData?.id && !hasSourceConnectionHandelC && handleCTitle?.length > 0) {
      delete data.handleCTitle;
      validateTextLength('', ctaLabelMaxLength, 'setHandleCTitle');
    }
  }, [hasSourceConnectionHandelC]);

  useEffect(() => {
    if (nodeData?.id && !hasSourceConnectionHandelD && handleDTitle?.length > 0) {
      delete data.handleDTitle;
      validateTextLength('', ctaLabelMaxLength, 'setHandleDTitle');
    }
  }, [hasSourceConnectionHandelD]);

  const isValidConnection = (connection) => {
    if (videoIsLandscape && ['c', 'd'].includes(connection.sourceHandle)) {
      return false;
    }

    if (connection.sourceHandle === 'a' && hasSourceConnectionHandelA) {
      return false;
    }
    if (connection.sourceHandle === 'b' && hasSourceConnectionHandelB) {
      return false;
    }
    if (connection.sourceHandle === 'c' && hasSourceConnectionHandelC) {
      return false;
    }

    if (connection.sourceHandle === 'd' && hasSourceConnectionHandelD) {
      return false;
    }

    return (
      connection.target !== connection.source &&
      getEdges()?.filter(
        (edge) => edge.target === connection.target && edge.source === connection.source,
      )?.length === 0
    );
  };

  const theme = getTheme('passive');

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  };

  const validateTextLength = (text, max, callbackName) => {
    eval(`${callbackName}`)(text);
    const isValid = text?.length <= max;
    if (!isValid) {
      eval(`${callbackName}Valid(false)`);
    } else {
      eval(`${callbackName}Valid(true)`);
    }
  };

  const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
  });

  const handleCtaClick = (handle) => {
    if (!showSettings) {
      setShowSettings(true);
    }

    setTimeout(
      () => {
        switch (handle) {
          case 'a':
            if (hasSourceConnectionHandelA) {
              aTitleInputRef.current.focus();
            }
            break;

          case 'b':
            if (hasSourceConnectionHandelB) {
              bTitleInputRef.current.focus();
            }
            break;

          case 'c':
            if (hasSourceConnectionHandelC) {
              cTitleInputRef.current.focus();
            }
            break;

          case 'd':
            if (hasSourceConnectionHandelD) {
              dTitleInputRef.current.focus();
            }
            break;
        }
      },
      showSettings ? 0 : 100,
    );
  };

  const handleOnFocus = () => {
    data.focus = true;
    const currentNodes = getNodes()?.map((n) => ({
      ...n,
      data: { ...n?.data, focus: n?.id === id },
    }));
    setNodes(currentNodes);
  };

  const handleOnBlur = () => {
    data.focus = false;

    const currentNodes = getNodes()?.map((n) => ({
      ...n,
      data: { ...n?.data, focus: false },
    }));
    setNodes(currentNodes);
  };

  function getButtonText(handleTitle, endScreenNodeKey, placeholderKey) {
    if (handleTitle?.length > 0) {
      return handleTitle;
    }
    if (handleHasEndScreenNode(endScreenNodeKey)) {
      return getEndScreenNode(endScreenNodeKey)?.data?.endScreen?.meta?.endScreen?.btnTextString;
    }
    return `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "${placeholderKey}"`;
  }

  function getButtonClassName(handleTitle, endScreenNodeKey) {
    return `cta-button${
      handleTitle?.length > 0 || handleHasEndScreenNode(endScreenNodeKey) ? '' : ' placeholder'
    }`;
  }

  return (
    <div
      className={`videoFunnel-video-node${videoIsLandscape ? ' landscape-video' : ''}${
        hasTargetConnections ? ' hasTarget' : ''
      }`}
    >
      <Handle
        id="a"
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        isValidConnection={isValidConnection}
      />
      <div>
        <div style={{ display: 'flex' }}>
          <div className="video-card-source-container">
            <Fade in={hasAtLeastOneVideoNodes() || hasEndScreenNode()}>
              <div className="cta-overlay">
                <div className="buttons">
                  <Button
                    onClick={() => handleCtaClick('a')}
                    className={getButtonClassName(handleATitle, 'a')}
                    disabled={!hasSourceConnectionHandelA}
                  >
                    {getButtonText(handleATitle, 'a', 'A')}
                  </Button>
                  {(!isFreemium || freemiumVideoFunnelLimit?.connectionsPerCard >= 2) && (
                    <Button
                      onClick={() => handleCtaClick('b')}
                      className={getButtonClassName(handleBTitle, 'b')}
                      disabled={!hasSourceConnectionHandelB}
                    >
                      {getButtonText(handleBTitle, 'b', 'B')}
                    </Button>
                  )}
                  {!videoIsLandscape && (
                    <>
                      {(!isFreemium || freemiumVideoFunnelLimit?.connectionsPerCard >= 3) && (
                        <Button
                          onClick={() => handleCtaClick('c')}
                          className={getButtonClassName(handleCTitle, 'c')}
                          disabled={!hasSourceConnectionHandelC}
                        >
                          {getButtonText(handleCTitle, 'c', 'C')}
                        </Button>
                      )}
                      {(!isFreemium || freemiumVideoFunnelLimit?.connectionsPerCard >= 4) && (
                        <Button
                          onClick={() => handleCtaClick('d')}
                          className={getButtonClassName(handleDTitle, 'd')}
                          disabled={!hasSourceConnectionHandelD}
                        >
                          {getButtonText(handleDTitle, 'd', 'D')}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Fade>
            <VideoCard
              key={videoCard.id}
              playMode
              theme={theme}
              videoCard={videoCard}
              customVideoTitle={videoTitle?.trim()?.length > 0 ? videoTitle?.trim() : null}
              orgData={basicOrgData}
              updateVideoCard={() => {}}
              height={cardHeight}
              width={cardWidth}
              padding={cardHeight * 0.025}
              fontSize="18px"
              setAffectedItem={() => {}}
              setInfo={() => {}}
              handleUpdateBoard={() => {}}
              suggestedQuestions={[]}
              orgRecipients={[]}
              setError={() => {}}
              handleDuplicateItemClick={() => {}}
              allowFullscreen={false}
              videoIsLandscape={videoIsLandscape}
              setVideoIsLandscape={setVideoIsLandscape}
              setVideoDuration={(e) => {
                if (!videoDuration) {
                  setVideoDuration(e);
                  if (!(ctaTiming?.start >= 0 && ctaTiming?.end)) {
                    setCtaTiming({
                      start: Math.min(3, Math.max(parseInt(e, 10) - 3, 0)),
                      end: e,
                    });
                  }
                }
              }}
            />
          </div>

          <Collapse in={showSettings} orientation="horizontal">
            <div className="video-card-source-container-settings">
              <div className="inner-upper">
                <div className="cta-options-header">
                  <Typography variant="headlineMedium">
                    {translationsStoryline.videoFunnel.videoNodeOptions[lang]}
                  </Typography>
                </div>
                <div className="video-title">
                  <label className={`${videoTitle ? '' : 'error'}`}>
                    <Tooltip
                      title={[
                        ...translationsStoryline.videoFunnel.tooltips.editVideoTitle[lang],
                        videoCard?.question?.[lang],
                      ]}
                      enterDelay={500}
                      enterNextDelay={100}
                    >
                      <Typography variant="titleeMedium" className="video-title">
                        {translationsStoryline.videoFunnel.videoTitle[lang]}
                      </Typography>
                    </Tooltip>
                    <Typography variant="bodySmall" className="error-msg">
                      {videoTitleValid
                        ? null
                        : translationsStoryline.videoFunnel.customCTLLabelError[lang]({
                            maxLength: videoTitleMaxLength,
                          })}
                    </Typography>
                    <input
                      type="text"
                      value={videoTitle}
                      placeholder={videoCard?.question?.[lang]}
                      maxLength={videoTitleMaxLength + 1}
                      onChange={(e) => {
                        validateTextLength(e.target.value, videoTitleMaxLength, 'setVideoTitle');
                        data.videoTitle = e.target.value;
                      }}
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                      onKeyUp={(e) => {
                        if (['Enter', 'Escape']?.includes(e.key)) {
                          videoTitleInputRef?.current?.blur();
                        }
                      }}
                      ref={videoTitleInputRef}
                      style={{
                        ...(!videoTitleValid
                          ? {
                              border: '1px solid rgb(244, 67, 54)',
                              boxShadow: 'rgba(244, 67, 54, 0.25) 0px 0px 0px 0.2rem',
                            }
                          : {}),
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="inner-lower">
                <Box>
                  <PopupState variant="popover">
                    {(popupState) => (
                      <div>
                        <label style={{ display: 'grid' }}>
                          <b style={{ ...labelB, fontSize: '16px' }}>
                            {translationsStoryline.videoFunnel.timing[lang]}
                          </b>
                          <span style={{ ...labelSpanDescription }}>
                            {translationsStoryline.videoFunnel.showAfter[lang]}:
                            <input
                              type="text"
                              value={formatDuration(parseInt(ctaTiming.start, 10))}
                              disabled={!(hasLeastOneSourceConnections || hasEndScreenNode())}
                              onChange={(e) => {}}
                              max={formatDuration(parseInt(videoDuration, 10))}
                              min={formatDuration(0)}
                              onClick={() => {
                                popupState.open();
                              }}
                              {...bindTrigger(popupState)}
                              style={{ width: '60px', marginLeft: '10px' }}
                            />
                          </span>
                        </label>

                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          PaperProps={{
                            style: {
                              borderRadius: '10px',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              margin: '15px',
                              minHeight: '50px',
                              display: 'grid',
                              width: '320px',
                              borderRadius: '10px',
                            }}
                          >
                            <IconButton
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                              }}
                              onClick={() => {
                                popupState.close();
                              }}
                            >
                              <i className="icon">close</i>
                            </IconButton>
                            <b style={{ ...labelB }}>
                              {translationsStoryline.videoFunnel.ctaTiming[lang]}
                            </b>
                            <span style={{ ...labelSpanDescription }}>
                              {translationsStoryline.videoFunnel.ctaTimingDescription[lang]}
                            </span>
                            <div>
                              <SimpleSlider
                                aria-label="time-indicator"
                                size="small"
                                value={parseInt(ctaTiming.start, 10)}
                                min={0}
                                step={1}
                                max={parseInt(videoDuration, 10)}
                                track="inverted"
                                onChange={(_, value) => {
                                  setCtaTiming((old) => ({ ...old, start: Math.max(0, value) }));
                                }}
                                valueLabelDisplay="auto"
                                sx={{ width: '100%' }}
                                valueLabelFormat={(value) => formatDuration(parseInt(value, 10))}
                              />
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  mt: -2,
                                }}
                              >
                                <TinyText>{formatDuration(parseInt(ctaTiming.start, 10))}</TinyText>
                                <TinyText>{formatDuration(parseInt(videoDuration, 10))}</TinyText>
                              </Box>
                              <Box />
                            </div>
                          </Box>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </Box>

                <SimpleDivider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <label className={`${handleATitle ? '' : 'error'}`}>
                  <span className="label-title">A:</span>
                  <Typography variant="bodySmall" className="error-msg">
                    {handleATitleValid
                      ? null
                      : translationsStoryline.videoFunnel.customCTLLabelError[lang]({
                          maxLength: ctaLabelMaxLength,
                        })}
                  </Typography>
                  <input
                    type="text"
                    value={handleATitle}
                    placeholder={
                      handleHasEndScreenNode('a')
                        ? getEndScreenNode('a')?.data?.endScreen?.meta?.endScreen?.btnTextString
                        : `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "A"`
                    }
                    disabled={
                      !hasSourceConnectionHandelA ||
                      !(
                        hasAtLeastOneVideoNodes() ||
                        (hasLeastOneSourceConnections && hasEndScreenNode()) ||
                        handleHasEndScreenNode('a')
                      )
                    }
                    maxLength={ctaLabelMaxLength + 1}
                    onChange={(e) => {
                      validateTextLength(e.target.value, ctaLabelMaxLength, 'setHandleATitle');
                      data.handleATitle = e.target.value;
                    }}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyUp={(e) => {
                      if (['Enter', 'Escape']?.includes(e.key)) {
                        aTitleInputRef?.current?.blur();
                      }
                    }}
                    ref={aTitleInputRef}
                    style={{
                      ...(!handleATitleValid
                        ? {
                            border: '1px solid rgb(244, 67, 54)',
                            boxShadow: 'rgba(244, 67, 54, 0.25) 0px 0px 0px 0.2rem',
                          }
                        : {}),
                    }}
                  />
                </label>
                <SimpleDivider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <label className={`${handleBTitle ? '' : 'error'}`}>
                  <span className="label-title">B:</span>
                  <Typography variant="bodySmall" className="error-msg">
                    {handleBTitleValid
                      ? null
                      : translationsStoryline.videoFunnel.customCTLLabelError[lang]({
                          maxLength: ctaLabelMaxLength,
                        })}
                  </Typography>
                  <input
                    type="text"
                    value={handleBTitle}
                    placeholder={
                      handleHasEndScreenNode('b')
                        ? getEndScreenNode('b')?.data?.endScreen?.meta?.endScreen?.btnTextString
                        : `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "B"`
                    }
                    disabled={
                      !hasSourceConnectionHandelB ||
                      !(
                        hasAtLeastOneVideoNodes() ||
                        (hasLeastOneSourceConnections && hasEndScreenNode()) ||
                        handleHasEndScreenNode('b')
                      )
                    }
                    maxLength={ctaLabelMaxLength + 1}
                    onChange={(e) => {
                      validateTextLength(e.target.value, ctaLabelMaxLength, 'setHandleBTitle');
                      data.handleBTitle = e.target.value;
                    }}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyUp={(e) => {
                      if (['Enter', 'Escape']?.includes(e.key)) {
                        bTitleInputRef?.current?.blur();
                      }
                    }}
                    ref={bTitleInputRef}
                    style={{
                      ...(!handleBTitleValid
                        ? {
                            border: '1px solid rgb(244, 67, 54)',
                            boxShadow: 'rgba(244, 67, 54, 0.25) 0px 0px 0px 0.2rem',
                          }
                        : {}),
                    }}
                  />
                </label>
                {!videoIsLandscape && (
                  <>
                    <SimpleDivider style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <label className={`${handleCTitle ? '' : 'error'}`}>
                      <span className="label-title">C:</span>
                      <Typography variant="bodySmall" className="error-msg">
                        {handleCTitleValid
                          ? null
                          : translationsStoryline.videoFunnel.customCTLLabelError[lang]({
                              maxLength: ctaLabelMaxLength,
                            })}
                      </Typography>
                      <input
                        type="text"
                        value={handleCTitle}
                        placeholder={
                          handleHasEndScreenNode('c')
                            ? getEndScreenNode('c')?.data?.endScreen?.meta?.endScreen?.btnTextString
                            : `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "C"`
                        }
                        disabled={
                          !hasSourceConnectionHandelC ||
                          !(
                            hasAtLeastOneVideoNodes() ||
                            (hasLeastOneSourceConnections && hasEndScreenNode()) ||
                            handleHasEndScreenNode('c')
                          )
                        }
                        maxLength={ctaLabelMaxLength + 1}
                        onChange={(e) => {
                          validateTextLength(e.target.value, ctaLabelMaxLength, 'setHandleCTitle');
                          data.handleCTitle = e.target.value;
                        }}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onKeyUp={(e) => {
                          if (['Enter', 'Escape']?.includes(e.key)) {
                            cTitleInputRef?.current?.blur();
                          }
                        }}
                        ref={cTitleInputRef}
                        style={{
                          ...(!handleCTitleValid
                            ? {
                                border: '1px solid rgb(244, 67, 54)',
                                boxShadow: 'rgba(244, 67, 54, 0.25) 0px 0px 0px 0.2rem',
                              }
                            : {}),
                        }}
                      />
                    </label>
                    <SimpleDivider style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <label className={`${handleDTitle ? '' : 'error'}`}>
                      <span className="label-title">D:</span>
                      <Typography variant="bodySmall" className="error-msg">
                        {handleDTitleValid
                          ? null
                          : translationsStoryline.videoFunnel.customCTLLabelError[lang]({
                              maxLength: ctaLabelMaxLength,
                            })}
                      </Typography>
                      <input
                        type="text"
                        value={handleDTitle}
                        placeholder={
                          handleHasEndScreenNode('d')
                            ? getEndScreenNode('d')?.data?.endScreen?.meta?.endScreen?.btnTextString
                            : `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "D"`
                        }
                        disabled={
                          !hasSourceConnectionHandelD ||
                          !(
                            hasAtLeastOneVideoNodes() ||
                            (hasLeastOneSourceConnections && hasEndScreenNode()) ||
                            handleHasEndScreenNode('d')
                          )
                        }
                        maxLength={ctaLabelMaxLength + 1}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onChange={(e) => {
                          validateTextLength(e.target.value, ctaLabelMaxLength, 'setHandleDTitle');
                          data.handleDTitle = e.target.value;
                        }}
                        onKeyUp={(e) => {
                          if (['Enter', 'Escape']?.includes(e.key)) {
                            dTitleInputRef?.current?.blur();
                          }
                        }}
                        ref={dTitleInputRef}
                        style={{
                          ...(!handleDTitleValid
                            ? {
                                border: '1px solid rgb(244, 67, 54)',
                                boxShadow: 'rgba(244, 67, 54, 0.25) 0px 0px 0px 0.2rem',
                              }
                            : {}),
                        }}
                      />
                    </label>
                  </>
                )}
              </div>
            </div>
          </Collapse>
        </div>

        <Button
          // variant=""
          // color=""
          className="expand-collapse-btn"
          onClick={() => setShowSettings(!showSettings)}
          sx={{ boxShadow: 2 }}
        >
          {showSettings ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
        </Button>
      </div>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable && !hasSourceConnectionHandelA}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelA}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelA}
        isValidConnection={isValidConnection}
        className={`${videoIsLandscape ? ' landscape-video' : ''}${
          hasSourceConnectionHandelA ? ' connectionindicator pointer' : ''
        }`}
        hasSourceConnectionHandel={hasSourceConnectionHandelA}
      >
        A
      </HandleToolTip>

      <HandleToolTip
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable && !hasSourceConnectionHandelB}
        isConnectableStart={isConnectable && !hasSourceConnectionHandelB}
        isConnectableEnd={isConnectable && !hasSourceConnectionHandelB}
        isValidConnection={isValidConnection}
        className={`${videoIsLandscape ? ' landscape-video' : ''}${
          hasSourceConnectionHandelB ? ' connectionindicator pointer' : ''
        }`}
        hasSourceConnectionHandel={hasSourceConnectionHandelB}
      >
        B
      </HandleToolTip>

      {!videoIsLandscape && (
        <>
          {(!isFreemium || freemiumVideoFunnelLimit?.connectionsPerCard >= 3) && (
            <HandleToolTip
              type="source"
              position={Position.Right}
              id="c"
              isConnectable={isConnectable && !hasSourceConnectionHandelC && !videoIsLandscape}
              isConnectableStart={isConnectable && !hasSourceConnectionHandelC}
              isConnectableEnd={isConnectable && !hasSourceConnectionHandelC}
              isValidConnection={isValidConnection}
              className={`${videoIsLandscape ? ' landscape-video' : ''}${
                hasSourceConnectionHandelC ? ' connectionindicator pointer' : ''
              }`}
              hasSourceConnectionHandel={hasSourceConnectionHandelC}
            >
              C
            </HandleToolTip>
          )}

          {(!isFreemium || freemiumVideoFunnelLimit?.connectionsPerCard >= 4) && (
            <HandleToolTip
              type="source"
              position={Position.Right}
              id="d"
              isConnectable={isConnectable && !hasSourceConnectionHandelD}
              isConnectableStart={isConnectable && !hasSourceConnectionHandelD}
              isConnectableEnd={isConnectable && !hasSourceConnectionHandelD}
              isValidConnection={isValidConnection}
              className={`${videoIsLandscape ? ' landscape-video' : ''}${
                hasSourceConnectionHandelD ? ' connectionindicator pointer' : ''
              }`}
              hasSourceConnectionHandel={hasSourceConnectionHandelD}
            >
              D
            </HandleToolTip>
          )}
        </>
      )}
    </div>
  );
}

export default VideoFunnelVideoNode;

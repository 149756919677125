import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import PremiumFeatureCheckbox from './PremiumFeatureCheckbox';

import { PremiumFeatures } from './types';

interface EditPremiumFeaturesViewProps {
  createNewOrg?: boolean;
  premiumFeatures: PremiumFeatures;
  setPremiumFeatures: React.Dispatch<React.SetStateAction<PremiumFeatures | undefined>>;
  orgData: {
    users: [{ id: number }];
  };
}

function EditPremiumFeaturesView({
  premiumFeatures,
  setPremiumFeatures,
  createNewOrg = false,
  orgData,
}: EditPremiumFeaturesViewProps) {
  const handleFeatureUpdate = useCallback(
    (updatedFeatures: Partial<PremiumFeatures>) => {
      setPremiumFeatures((prevFeatures) => {
        const safePrevFeatures = prevFeatures || ({} as PremiumFeatures);

        // Function to remove __typename if it exists
        const removeTypename = (features: Partial<PremiumFeatures>) => {
          const newFeatures = { ...features };
          if ('__typename' in newFeatures) {
            delete newFeatures.__typename;
          }
          return newFeatures;
        };

        // Ensure no undefined values for required fields
        return {
          ...removeTypename(safePrevFeatures),
          ...removeTypename(updatedFeatures),
        } as PremiumFeatures; // Ensure the result is of type PremiumFeatures
      });
    },
    [setPremiumFeatures],
  );

  return (
    <Box>
      <Typography variant="titleLarge">Edit Premium Features</Typography>
      <PremiumFeatureCheckbox
        premiumFeatures={premiumFeatures}
        handleFeatureUpdate={handleFeatureUpdate}
        createNewOrg={createNewOrg}
        orgData={orgData}
      />
    </Box>
  );
}

export default EditPremiumFeaturesView;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Collapse from '@mui/material/Collapse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

import { useLanguage } from 'src/context/LanguageContext';
import { Stack } from '@mui/system';
import { useMutation, useQuery } from '@apollo/client';
import VideoFunnelReactFlow from './VideoFunnelReactFlow';
import VideoFunnelAddVideoDialog from './VideoFunnelAddVideoDialog';
import VideoFunnelHeader from './VideoFunnelHeader';
import SettingsFooter from './VideoFunnelSettingsFooter';

import { translationsStoryline } from '../../../utils/translationsStoryline';
import { packageVariant, storylineStatuses } from '../../../utils/utils';
import { translations } from '../../../utils/translations';
import { widgetPath } from '../../../utils/environment';
import EditTextInput from '../../common/EditTextInput';
import SimpleDivider from '../../common/SimpleDivider';

import { getTranslationKey } from '../Storyline/StorylineLinkWhenCondition';
import { formatUrlsJson } from '../Storyline/StorylineCreateEdit';

import StorylineLinkHandler, {
  checkIfUrlWhenConditionIsUsed,
  checkIfUrlWhenConditionIsUsedByThis,
  checkIfHasAnyConditionWhen,
  checkIfHasAnyExactMatchInvalid,
} from '../Storyline/StorylineLinkHandler';
import getTheme from '../../common/SplitButton/SplitButtonThemes';

import AlertDialog from '../../common/AlertDialog';

import arrowEndMarker from './arrowEndMarker';

import SplitButton from '../../common/SplitButton/SplitButton';

import VideoFunnelAddEndScreenDialog from './VideoFunnelAddEndScreenDialog';
import StoryAnalytics from '../StoryAnalytics/StoryAnalytics';
import {
  CREATE_VIDEO_FUNNEL_MUTATION,
  DELETE_VIDEO_FUNNEL_MUTATION,
  FETCH_VIDEO_FUNNEL_QUERY,
  UPDATE_VIDEO_FUNNEL_MUTATION,
} from './graphql';

const initialNode = (lang) => ({
  id: 'start-node',
  type: 'videoFunnelStartNode',
  data: {
    label: translationsStoryline.videoFunnel.start[lang],
    id: 'start-node',
    isStartNode: true,
  },
  deletable: false,
  position: { x: 0, y: 0 },
});

const initialEdgesNewVideoFunnel = () => [
  {
    ...arrowEndMarker(),
    id: `initial-edge-1`,
    newEdge: true,
    source: 'start-node',
    sourceHandle: 'a',
    target: 'empty-video-node-1',
    targetHandle: 'a',
  },
  {
    ...arrowEndMarker(),
    id: `initial-edge-2`,
    newEdge: true,
    source: 'empty-video-node-1',
    sourceHandle: 'a',
    target: 'empty-video-node-2',
    targetHandle: 'a',
  },
  {
    ...arrowEndMarker(),
    id: `initial-edge-3`,
    newEdge: true,
    source: 'empty-video-node-1',
    sourceHandle: 'b',
    target: 'empty-video-node-3',
    targetHandle: 'a',
  },
];

const initialNodesNewVideoFunnel = (lang) => [
  { ...initialNode(lang), newNode: true },
  {
    id: 'empty-video-node-1',
    type: 'videoFunnelEmptyVideoNode',
    data: {
      id: 'empty-video-node-1',
    },
    deletable: true,
    position: { x: 229, y: -243 },
  },
  {
    id: 'empty-video-node-2',
    type: 'videoFunnelEmptyVideoNode',
    data: {
      id: 'empty-video-node-2',
    },
    deletable: true,
    position: { x: 1030, y: -560 },
  },
  {
    id: 'empty-video-node-3',
    type: 'videoFunnelEmptyVideoNode',
    data: {
      id: 'empty-video-node-3',
    },
    deletable: true,
    position: { x: 1030, y: 122 },
  },
];

export default function VideoFunnelCreate() {
  const lang = useLanguage();
  const { basicOrgData, storylineObjects, videoFunnelObjects } = useOutletContext();
  const orgHasEndScreen = basicOrgData?.premiumFeatures?.interactionOption ?? false;

  const numLiveStories = [
    ...(storylineObjects.current ?? []),
    ...(videoFunnelObjects.current ?? []),
  ].filter((s) => s.status === storylineStatuses.LIVE).length;
  const isFreemium = basicOrgData?.premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumStoriesLimit =
    isFreemium && basicOrgData?.premiumFeatures?.freemiumFeatures?.stories;
  const orgHasVideoFunnelEndScreen =
    (basicOrgData?.premiumFeatures?.interactionOption &&
      basicOrgData?.premiumFeatures?.videoFunnelEndScreen) ??
    false;

  const navigate = useNavigate();
  const location = useLocation();
  const theme = getTheme('active');
  const [collapseSettings, setCollapseSettings] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [videoFunnelItem, setVideoFunnelItem] = useState(location?.state?.videoFunnelData);

  const [openAddVideoEmpty, setOpenAddVideoEmpty] = useState(false);

  const [openAddEndScreen, setOpenAddEndScreen] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const otherStorylinesUrls = (location?.state?.urlsJsonb ?? [])?.filter(
    (urlObj) => urlObj.storylineId !== videoFunnelItem?.id || !videoFunnelItem?.id,
  );

  const videoFunnelId = (videoFunnelItem && videoFunnelItem.id) || null;
  const status = videoFunnelItem?.status ? videoFunnelItem.status : storylineStatuses.EDIT;
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [title, setTitle] = useState(videoFunnelItem?.title ?? '');

  const [nodes, setNodes] = useState(() => {
    if (!videoFunnelId) {
      return initialNodesNewVideoFunnel(lang);
    }
    if (videoFunnelItem?.videoFunnelNodes?.length > 0) {
      const tempNodes = videoFunnelItem.nodes;
      const startNodeIndex = tempNodes?.findIndex((n) => n.isStartNode);
      if (startNodeIndex) {
        tempNodes[startNodeIndex] = { ...initialNode(lang), ...tempNodes[startNodeIndex] };
        return tempNodes;
      }
      return [{ ...initialNode(lang), newNode: true }, ...(videoFunnelItem.nodes ?? [])];
    }

    return [{ ...initialNode(lang), newNode: true }];
  });

  const [edges, setEdges] = useState(() => {
    if (!videoFunnelId) {
      return initialEdgesNewVideoFunnel(lang);
    }
    if (videoFunnelItem?.videoFunnelEdges?.length > 0) {
      return videoFunnelItem.edges;
    }
    return [];
  });

  const [selection, setSelection] = useState({ nodes: [], edges: [] });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [simpleAlertDialogDeleteOpen, setSimpleAlertDialogDeleteOpen] = useState(false);
  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);

  const [urlsJsonb, setUrlsJsonb] = useState(
    videoFunnelItem?.urlsJsonb?.map((u, index) => ({
      ...u,
      index,
      ...(!u?.conditionWhen && !u?.excludedWhen
        ? {
            conditionWhen: getTranslationKey(
              translationsStoryline.availableWhenConditions.exactMatch,
            ),
          }
        : {}),
    })) ?? [],
  );

  const {
    data: videoFunnelData,
    loading: videoFunnelLoading,
    error: videoFunnelError,
  } = useQuery(FETCH_VIDEO_FUNNEL_QUERY, {
    variables: {
      encodedOrgId: basicOrgData.encodedId,
      input: {
        id: videoFunnelId,
        orgId: basicOrgData.id,
      },
    },
    skip: !videoFunnelId,
  });

  // Use useMutation for create, update, delete
  const [createVideoFunnelMutation] = useMutation(CREATE_VIDEO_FUNNEL_MUTATION);
  const [updateVideoFunnelMutation] = useMutation(UPDATE_VIDEO_FUNNEL_MUTATION);
  const [deleteVideoFunnelMutation] = useMutation(DELETE_VIDEO_FUNNEL_MUTATION);

  const hasSetVideoFunnelData = useRef(false);

  useEffect(() => {
    if (!hasSetVideoFunnelData.current && videoFunnelData && videoFunnelData.fetchVideoFunnel) {
      hasSetVideoFunnelData.current = true;

      setVideoFunnelItem(videoFunnelData.fetchVideoFunnel);

      const fetchNodes = videoFunnelData.fetchVideoFunnel.videoFunnelNodes?.map((nodeData) => {
        if (nodeData?.data?.videoCard?.id) {
          return {
            ...nodeData,
            id: `${nodeData.id}`,
            type: 'videoFunnelVideoNode',
            position: { x: nodeData.position.x ?? 0, y: nodeData.position.y ?? 0 },
            data: { ...nodeData.data, id: nodeData.id, videoCard: nodeData.data.videoCard },
          };
        }
        if (nodeData?.data?.isStartNode) {
          const tempInitialNode = initialNode(lang);
          return {
            ...tempInitialNode,
            id: `${nodeData.id}`,
            type: 'videoFunnelStartNode',
            position: { x: nodeData.position.x ?? 0, y: nodeData.position.y ?? 0 },
            data: { ...tempInitialNode.data, id: nodeData.id },
          };
        }
        if (nodeData?.data?.endScreen?.id) {
          return {
            ...nodeData,
            id: `${nodeData.id}`,
            type: 'videoFunnelEndScreenNode',
            position: { x: nodeData.position.x ?? 0, y: nodeData.position.y ?? 0 },
            data: { ...nodeData.data, id: nodeData.id, endScreen: nodeData.data.endScreen },
          };
        }
      });

      if (fetchNodes?.length > 0) {
        setNodes(fetchNodes?.filter((n) => !!n));

        const fetchEdges = videoFunnelData.fetchVideoFunnel.videoFunnelEdges?.map((edgeData) => {
          return {
            ...arrowEndMarker(),
            id: `${edgeData.id}`,
            source: `${edgeData.source}`,
            sourceHandle: `${edgeData.sourceHandle}`,
            target: `${edgeData.target}`,
            targetHandle: `${edgeData.targetHandle}`,
          };
        });
        if (fetchEdges?.length > 0) {
          setEdges(fetchEdges);
        }
      }
    }
  }, [videoFunnelData, lang]);

  useEffect(() => {
    if (
      !dataFetched &&
      reactFlowInstance &&
      videoFunnelItem?.videoFunnelNodes?.length > 0 &&
      nodes?.length > 0
    ) {
      setDataFetched(true);
      setTimeout(() => {
        reactFlowInstance.fitView({ duration: 500 });
      }, 150);
    }
  }, [dataFetched, reactFlowInstance, videoFunnelItem, nodes]);

  const hasChanges = () => {
    if (edges?.some((edge) => edge?.newEdge)) {
      return true;
    }

    if (nodes?.some((node) => node?.newNode && !node?.data?.isStartNode)) {
      return true;
    }

    if (videoFunnelId) {
      if (
        (videoFunnelItem?.title?.length > 0 ||
          videoFunnelItem?.title === undefined ||
          !videoFunnelItem?.title) &&
        videoFunnelItem?.title !== title
      ) {
        return true;
      }
      if (videoFunnelItem?.videoFunnelNodes?.length !== nodes?.length) {
        return true;
      }
      if (videoFunnelItem?.videoFunnelEdges?.length !== edges?.length) {
        return true;
      }
      if (
        videoFunnelItem?.videoFunnelEdges?.filter((edge) => !edge?.newEdge)?.length !==
        videoFunnelItem?.videoFunnelEdges?.length
      ) {
        return true;
      }
      if (
        videoFunnelItem?.videoFunnelNodes?.filter((node) => !node?.newNode)?.length !==
        videoFunnelItem?.videoFunnelNodes?.length
      ) {
        return true;
      }
      if (
        videoFunnelItem?.urlsJsonb?.length >= 0 &&
        urlsJsonb?.filter((urlObj) => urlObj?.url?.trim() !== '' && !!urlObj?.url)?.length !==
          videoFunnelItem?.urlsJsonb?.length
      ) {
        return true;
      }

      const handleTitleChanges = videoFunnelItem?.videoFunnelNodes
        ?.filter((node) => !node?.newNode && !node?.data?.isStartNode)
        ?.some((node) => {
          const oldNodeData = node?.data;
          const newNodeData = nodes.find((n) => Number(n.id) === Number(node.id))?.data;

          if (
            (!oldNodeData?.handleATitle && newNodeData?.handleATitle?.length > 0) ||
            (oldNodeData?.handleATitle && oldNodeData?.handleATitle !== newNodeData?.handleATitle)
          ) {
            return true;
          }
          if (
            (!oldNodeData?.handleBTitle && newNodeData?.handleBTitle?.length > 0) ||
            (oldNodeData?.handleBTitle && oldNodeData?.handleBTitle !== newNodeData?.handleBTitle)
          ) {
            return true;
          }
          if (!oldNodeData?.videoIsLandscape) {
            if (
              (!oldNodeData?.handleCTitle && newNodeData?.handleCTitle?.length > 0) ||
              (oldNodeData?.handleCTitle && oldNodeData?.handleCTitle !== newNodeData?.handleCTitle)
            ) {
              return true;
            }

            if (
              (!oldNodeData?.handleDTitle && newNodeData?.handleDTitle?.length > 0) ||
              (oldNodeData?.handleDTitle && oldNodeData?.handleDTitle !== newNodeData?.handleDTitle)
            ) {
              return true;
            }
          }
          return false;
        });

      if (handleTitleChanges) {
        return true;
      }

      if (
        videoFunnelItem?.urlsJsonb?.length > 0 &&
        !videoFunnelItem?.urlsJsonb?.every((urlObj) => {
          if (
            (urlObj?.conditionWhen && !urlObj?.excludedWhen) ||
            (!urlObj?.conditionWhen && !urlObj?.excludedWhen)
          ) {
            const conditionWhen =
              urlObj?.conditionWhen ||
              getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch);

            return urlsJsonb?.find(
              (u) =>
                u?.conditionWhen === conditionWhen &&
                u?.url === urlObj?.url &&
                (u?.override ?? false) === (urlObj?.override ?? false),
            );
          }
          if (urlObj?.excludedWhen) {
            return urlsJsonb?.find(
              (u) => u?.excludedWhen === urlObj.excludedWhen && u?.url === urlObj?.url,
            );
          }
        })
      ) {
        return true;
      }

      const videoTitleChanges = videoFunnelItem?.videoFunnelNodes

        ?.filter((node) => !node?.newNode && !node?.data?.isStartNode)
        ?.some((node) => {
          const oldNodeData = node?.data;
          const newNodeData = nodes.find((n) => Number(n.id) === Number(node.id))?.data;
          if (
            (!oldNodeData?.videoTitle && newNodeData?.videoTitle?.length > 0) ||
            (oldNodeData?.videoTitle && oldNodeData?.videoTitle !== newNodeData?.videoTitle)
          ) {
            return true;
          }
        });

      if (videoTitleChanges) {
        return true;
      }

      const videoNodeTimingChanges = videoFunnelItem?.videoFunnelNodes

        ?.filter((node) => !node?.newNode && !node?.data?.isStartNode)
        ?.some((node) => {
          const oldNodeData = node?.data;
          const newNodeData = nodes.find((n) => Number(n.id) === Number(node.id))?.data;
          if (
            (!oldNodeData?.ctaTiming?.start && newNodeData?.ctaTiming?.start >= 0) ||
            (!oldNodeData?.ctaTiming?.end && newNodeData?.ctaTiming?.end >= 0) ||
            (oldNodeData?.ctaTiming?.start &&
              oldNodeData?.ctaTiming?.start !== newNodeData?.ctaTiming?.start) ||
            (oldNodeData?.ctaTiming?.end &&
              oldNodeData?.ctaTiming?.end !== newNodeData?.ctaTiming?.end)
          ) {
            return true;
          }
        });

      if (videoNodeTimingChanges) {
        return true;
      }

      return false;
    }
    return (
      title !== '' ||
      nodes?.length !== 0 ||
      edges?.length !== 0 ||
      startDate !== null ||
      endDate !== null ||
      urlsJsonb?.length !== 0
    );
  };

  const removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
  };

  const getNodes = useCallback(() => {
    return nodes?.map((node) =>
      removeEmpty({
        id: node?.id,
        orgId: basicOrgData?.orgId,
        videoFunnelId,
        videoTitle: node?.data?.videoTitle?.trim(),
        videoCardId: node?.data?.videoCard?.id,
        endScreenId: node?.data?.endScreen?.id,
        positionX: node?.position?.x ?? 0,
        positionY: node?.position?.y ?? 0,
        type: node?.type,
        isStartNode: node?.data?.isStartNode || false,
        newNode: node?.newNode || false,
        handleATitle: node?.data?.handleATitle,
        handleBTitle: node?.data?.handleBTitle,
        handleCTitle: node?.data?.handleCTitle,
        handleDTitle: node?.data?.handleDTitle,
        ctaTimingStart:
          parseInt(node?.data?.ctaTiming?.start, 10) >= 0
            ? parseInt(node?.data?.ctaTiming?.start, 10)
            : null,
        ctaTimingEnd:
          parseInt(node?.data?.ctaTiming?.end, 10) >= 0
            ? parseInt(node?.data?.ctaTiming?.end, 10)
            : null,
      }),
    );
  }, [nodes, basicOrgData, videoFunnelId]);

  const getEdges = useCallback(() => {
    return edges?.map((edge) =>
      removeEmpty({
        id: edge?.id,
        orgId: basicOrgData?.orgId,
        videoFunnelId,
        source: edge?.source,
        sourceHandle: edge?.sourceHandle,
        target: edge?.target,
        targetHandle: edge?.targetHandle,
        newEdge: edge?.newEdge || false,
      }),
    );
  }, [edges, basicOrgData, videoFunnelId]);

  const handleNavigateBack = useCallback(
    ({ succesMsg }) => {
      navigate(`/${basicOrgData.orgNameSlug}/backoffice/stories`, {
        state: { ...(succesMsg ? { succesMsg, storylineData: videoFunnelItem } : {}) },
      });
    },
    [navigate, basicOrgData.orgNameSlug, videoFunnelItem],
  );

  const handleSave = useCallback(
    ({ newStatus = status || storylineStatuses.EDIT }) => {
      const input = {
        orgId: basicOrgData.id,
        title,
        status: newStatus,
        urlsJsonb: formatUrlsJson(urlsJsonb),
        startDate,
        endDate,
        videoFunnelNodes: getNodes(),
        videoFunnelEdges: getEdges(),
      };

      if (!videoFunnelItem?.id) {
        createVideoFunnelMutation({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            input,
          },
        })
          .then(() => {
            handleNavigateBack({
              succesMsg: translationsStoryline.videoFunnel.successFeedback.created[lang],
            });
          })
          .catch(() => {});
      } else {
        updateVideoFunnelMutation({
          variables: {
            encodedOrgId: basicOrgData.encodedId,
            input: {
              ...input,
              id: videoFunnelItem?.id,
            },
          },
        })
          .then(() => {
            handleNavigateBack({
              succesMsg: translationsStoryline.videoFunnel.successFeedback.updated[lang],
            });
          })
          .catch(() => {});
      }
    },
    [
      videoFunnelItem,
      basicOrgData,
      title,
      status,
      urlsJsonb,
      startDate,
      endDate,
      createVideoFunnelMutation,
      updateVideoFunnelMutation,
      handleNavigateBack,
      lang,
      getEdges,
      getNodes,
    ],
  );

  const onBackClick = () => {
    if (hasChanges()) {
      setSimpleAlertDialogOpen(true);
    } else {
      handleNavigateBack({});
    }
  };

  const startNodeHasConnections = () => {
    const startNode = nodes?.find(
      (n) => n?.data?.isStartNode && n?.type === 'videoFunnelStartNode',
    );
    if (!startNode) {
      return false;
    }
    return edges?.some(
      (edge) =>
        edge?.source === startNode?.id &&
        nodes?.find((n) => n?.id === edge?.target && n?.type === 'videoFunnelVideoNode'),
    );
  };

  const allNodesHasHandleTitles = () => {
    const allNodes = nodes?.filter((n) => {
      return !n?.data?.isStartNode;
    });
    if (!(allNodes?.length > 0)) {
      return true;
    }
    return allNodes?.every((n) => {
      const allNodeEdges =
        edges?.filter((e) => {
          return e?.source === n?.id;
        }) ?? [];
      if (allNodeEdges?.length <= 1) {
        return true;
      }

      const handleANode = nodes?.find(
        (NA) => NA?.id === allNodeEdges?.find((e) => e?.sourceHandle === 'a')?.target,
      );
      const handleBNode = nodes?.find(
        (NB) => NB?.id === allNodeEdges?.find((e) => e?.sourceHandle === 'b')?.target,
      );
      const handleCNode = nodes?.find(
        (NC) => NC?.id === allNodeEdges?.find((e) => e?.sourceHandle === 'c')?.target,
      );
      const handleDNode = nodes?.find(
        (ND) => ND?.id === allNodeEdges?.find((e) => e?.sourceHandle === 'd')?.target,
      );

      if (
        handleANode?.data?.videoCard?.id &&
        !(n?.data?.handleATitle?.length > 0) &&
        allNodeEdges?.find((e) => e?.sourceHandle === 'a')
      ) {
        return false;
      }
      if (
        handleBNode?.data?.videoCard?.id &&
        !(n?.data?.handleBTitle && n?.data?.handleBTitle?.length > 0) &&
        allNodeEdges?.find((e) => e?.sourceHandle === 'b')
      ) {
        return false;
      }
      if (!n?.data?.videoIsLandscape) {
        if (
          handleCNode?.data?.videoCard?.id &&
          !(n?.data?.handleCTitle && n?.data?.handleCTitle?.length > 0) &&
          allNodeEdges?.find((e) => e?.sourceHandle === 'c')
        ) {
          return false;
        }
        if (
          handleDNode?.data?.videoCard?.id &&
          !(n?.data?.handleDTitle && n?.data?.handleDTitle?.length > 0) &&
          allNodeEdges?.find((e) => e?.sourceHandle === 'd')
        ) {
          return false;
        }
      }

      return true;
    });
  };

  const linksAreValid = () => {
    if (checkIfUrlWhenConditionIsUsed({ urlsJsonb, otherStorylinesUrls })) {
      return false;
    }
    if (checkIfUrlWhenConditionIsUsedByThis({ urlsJsonb, otherStorylinesUrls })) {
      return false;
    }
    if (!checkIfHasAnyConditionWhen({ urlsJsonb }) && status === storylineStatuses.LIVE) {
      return false;
    }
    if (checkIfHasAnyExactMatchInvalid({ urlsJsonb })) {
      return false;
    }
    return true;
  };

  const canActivate = () => {
    if (status === storylineStatuses.LIVE) {
      return true;
    }

    if (!(title?.length > 0)) {
      return false;
    }
    if (!allNodesHasHandleTitles()) {
      return false;
    }

    if (!linksAreValid()) {
      return false;
    }

    return (
      status === storylineStatuses.LIVE ||
      (nodes?.filter((n) => !n?.data?.isStartNode)?.length > 0 && startNodeHasConnections())
    );
  };

  const canSave = () => {
    if (!(title?.length > 0)) {
      return false;
    }

    if (status === storylineStatuses.LIVE) {
      if (!allNodesHasHandleTitles()) {
        return false;
      }
      if (!canActivate()) {
        return false;
      }
    }

    if (!linksAreValid()) {
      return false;
    }

    return (
      ((status === storylineStatuses.EDIT && hasChanges()) ||
        (nodes?.filter((n) => !n?.data?.isStartNode)?.length > 0 && startNodeHasConnections())) &&
      hasChanges()
    );
  };

  const handleDelete = () => {
    setSimpleAlertDialogDeleteOpen(true);
  };

  const deleteItem = useCallback(() => {
    if (videoFunnelId) {
      deleteVideoFunnelMutation({
        variables: {
          encodedOrgId: basicOrgData.encodedId,
          input: {
            id: videoFunnelId,
            orgId: basicOrgData.id,
          },
        },
      })
        .then(() => {
          handleNavigateBack({
            succesMsg: translationsStoryline.videoFunnel.successFeedback.deleted[lang],
          });
        })
        .catch((e) => {});
    } else {
      handleNavigateBack({
        succesMsg: translationsStoryline.videoFunnel.successFeedback.deleted[lang],
      });
    }
  }, [videoFunnelId, deleteVideoFunnelMutation, basicOrgData, handleNavigateBack, lang]);

  document.onkeydown = (event) => {
    if (isFullscreen) {
      const evt = event || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        setIsFullscreen(false);
      }
    }
  };

  const getHandleTitle = useCallback(
    ({ node, edgeList, handle }) => {
      if (!handle || !node || !edgeList || !node?.data?.videoCard?.id) {
        return null;
      }

      if (edgeList?.filter((e) => e?.source === node?.id)?.length >= 1) {
        const getNextNode = (sourceHandle) =>
          nodes?.find(
            (n) =>
              n?.id ===
              edgeList?.find((e) => e?.source === node?.id && e?.sourceHandle === sourceHandle)
                ?.target,
          );

        const handleTitles = {
          a: {
            title: node?.data?.handleATitle,
            placeholder: `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "A"`,
          },
          b: {
            title: node?.data?.handleBTitle,
            placeholder: `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "B"`,
          },
          c: {
            title: node?.data?.handleCTitle,
            placeholder: `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "C"`,
          },
          d: {
            title: node?.data?.handleDTitle,
            placeholder: `${translationsStoryline.videoFunnel.ctaTitlePlaceholder[lang]} "D"`,
          },
        };

        const handleInfo = handleTitles[handle];
        if (handleInfo?.title?.length > 0) {
          return { [`handle${handle.toUpperCase()}Title`]: handleInfo.title };
        }
        const nextNode = getNextNode(handle);
        if (nextNode?.data?.videoCard?.id) {
          return { [`handle${handle.toUpperCase()}Title`]: handleInfo.placeholder };
        }
        return {};
      }
      return {};
    },
    [nodes, lang],
  );

  const openWidget = useCallback(() => {
    if (basicOrgData.encodedId) {
      const initScript = document.createElement('script');

      const edgesMapped = edges
        ?.filter(
          (e) =>
            nodes?.find(
              (n) =>
                n?.id === e?.source &&
                [
                  'videoFunnelVideoNode',
                  'videoFunnelStartNode',
                  'videoFunnelEndScreenNode',
                ]?.includes(n?.type),
            ) &&
            nodes?.find(
              (n) =>
                n?.id === e?.target &&
                [
                  'videoFunnelVideoNode',
                  'videoFunnelStartNode',
                  'videoFunnelEndScreenNode',
                ]?.includes(n?.type),
            ),
        )
        ?.map((e) =>
          removeEmpty({
            id: e.id,
            source: e.source,
            target: e.target,
            __typename: null,
            sourceHandle: e.sourceHandle,
            targetHandle: e.targetHandle,
          }),
        );

      const nodesData = nodes
        ?.filter((n) =>
          ['videoFunnelVideoNode', 'videoFunnelStartNode', 'videoFunnelEndScreenNode']?.includes(
            n?.type,
          ),
        )
        ?.map((n) => {
          return removeEmpty({
            id: n.id,
            __typename: null,
            data: removeEmpty({
              ...getHandleTitle({ node: n, edgeList: edgesMapped, handle: 'a' }),
              ...getHandleTitle({ node: n, edgeList: edgesMapped, handle: 'b' }),
              ...getHandleTitle({ node: n, edgeList: edgesMapped, handle: 'c' }),
              ...getHandleTitle({ node: n, edgeList: edgesMapped, handle: 'd' }),
              videoTitle: n?.data?.videoTitle,
              isStartNode: n?.data?.isStartNode,
              __typename: null,
              ...(n?.data?.ctaTiming?.start >= 0 && n?.data?.ctaTiming?.end >= 0
                ? removeEmpty({
                    ctaTiming: removeEmpty({ ...n?.data?.ctaTiming, __typename: null }),
                  })
                : {}),
              ...(n?.data?.videoCard?.id ? { videoCard: { id: n.data.videoCard.id } } : {}),
              ...(n?.data?.endScreen?.id ? { endScreen: { id: n.data.endScreen.id } } : {}),
            }),
          });
        });

      const inlineScript = document.createTextNode(`
        (function () {
          const settings = {
          id: '${basicOrgData.encodedId}',
          previewMode: ${true},
          nodes: ${JSON.stringify(nodesData)},
          edges: ${JSON.stringify(edgesMapped)},
        }
        WidgetWrapper.mount(settings)
        }())
        `);

      initScript.appendChild(inlineScript);
      initScript.async = true;

      document.body.appendChild(initScript);

      return () => {
        document.body.removeChild(initScript);
      };
    }
  }, [basicOrgData.encodedId, edges, nodes, getHandleTitle]);

  useEffect(() => {
    let script;
    if (showPreview) {
      script = document.createElement('script');
      script.src = widgetPath;
      script.async = true;
      script.onload = () => {
        openWidget();
      };

      document.body.appendChild(script);
    }

    return () => {
      // Always run the cleanup code
      try {
        window?.LIWidgetWrapper?.unmount();
      } catch (e) {
        // Handle error if necessary
      }
      const allScripts = document.body.getElementsByTagName('script');

      for (let i = allScripts.length - 1; i >= 0; i -= 1) {
        const suspect = allScripts[i];
        if (
          suspect?.src?.includes(widgetPath) ||
          suspect?.src?.includes('/widget.2.0.0') ||
          suspect?.src?.includes('/widget.3.0.0') ||
          suspect?.innerHTML?.includes('WidgetWrapper.mount(settings)') ||
          suspect?.innerText?.includes('WidgetWrapper.mount(settings)')
        ) {
          if (suspect.parentNode) {
            suspect.parentNode.removeChild(suspect);
          }
        }
      }

      const elem = document.getElementById('owli-widget');
      if (elem && elem.parentNode) {
        elem.parentNode.removeChild(elem);
      }

      if (script && document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [showPreview]);

  const removeSelection = () => {
    const currentEdges = edges;
    const currentNodes = nodes;

    const newNodes = currentNodes?.filter(
      (node) => !selection?.nodes?.find((n) => n.id === node.id),
    );

    const newEdges = currentEdges?.filter(
      (edge) =>
        !selection?.edges?.find((e) => e.id === edge.id) &&
        newNodes?.find((n) => {
          return n.id === edge.source;
        }) &&
        newNodes?.find((n) => n.id === edge.target),
    );

    setNodes(newNodes);
    setEdges(newEdges);

    setSelection({ nodes: [], edges: [] });
  };

  const handleOnAddVideoSelection = useCallback(
    ({ newVideoCards }) => {
      if (
        newVideoCards?.length === 1 &&
        selection?.nodes?.length === 1 &&
        !(selection?.edges?.length > 0)
      ) {
        const videoCard = newVideoCards[0];

        setNodes((prevNodes) => {
          const node = prevNodes?.find((n) => n.id === selection?.nodes[0]?.id);

          const newNodes = prevNodes?.filter((n) => n.id !== selection?.nodes[0]?.id);

          return [
            ...(newNodes?.length
              ? newNodes.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
              : []),
            {
              ...node,
              type: 'videoFunnelVideoNode',
              data: {
                label: videoCard?.question?.[lang],
                id: selection?.nodes[0]?.id,
                videoCard,
                shouldCheckConnections: true,
              },
              newNode: true,
            },
          ];
        });
      }
      setOpenAddVideoEmpty(false);
    },
    [selection?.edges?.length, selection?.nodes, lang],
  );

  const addVideoButtonOptions = [
    {
      label: translationsStoryline.videoFunnel.addVideo[lang],
      type: 'add-video',
    },

    ...(orgHasVideoFunnelEndScreen
      ? [{ label: translationsStoryline.videoFunnel.addEndScreen[lang], type: 'add-end-screen' }]
      : []),
  ];

  const handleClickSplitButtonAddVideo = (option) => {
    if (option?.type === 'add-video') {
      setOpenAddVideoEmpty(true);
    } else if (option?.type === 'add-end-screen') {
      setOpenAddEndScreen(true);
    }
  };

  const addEndScreenNode = ({ endScreen }) => {
    setNodes((els) => {
      const prevNodes = els;
      const node = prevNodes?.find((n) => n.id === selection?.nodes[0]?.id);

      const newNodes = prevNodes?.filter((n) => n.id !== selection?.nodes[0]?.id);

      return [
        ...(newNodes?.length
          ? newNodes.map((n) => ({ ...n, data: { ...n?.data, shouldCheckConnections: true } }))
          : []),
        {
          ...node,
          type: 'videoFunnelEndScreenNode',
          data: {
            endScreen,
            id: selection?.nodes[0]?.id,

            shouldCheckConnections: true,
          },
          newNode: true,
        },
      ];
    });
  };

  const handleCloseAddEndScreen = ({ newEndScreens }) => {
    setOpenAddEndScreen(false);
    if (newEndScreens?.length > 0) {
      newEndScreens?.forEach((newEndScreen, index) =>
        addEndScreenNode({
          endScreen: newEndScreen,

          index,
          isFullscreenView: isFullscreen,
        }),
      );
    }
  };

  return (
    <>
      <Box
        style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'scroll' }}
        sx={{ m: 2, mt: 1, ml: 3, mb: 8 }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: '3' }}
          open={showPreview}
          onClick={() => setShowPreview(!showPreview)}
        />
        <Box style={{ zIndex: isFullscreen ? 0 : 2, background: 'white' }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item sx={{ p: 0, width: '100%' }}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <VideoFunnelHeader
                  isEdit={videoFunnelItem && videoFunnelItem.id}
                  onBackClick={onBackClick}
                />
                {videoFunnelItem?.id && (
                  <StoryAnalytics
                    identifier={{ videoFunnelId: videoFunnelItem.id }}
                    encodedOrgId={basicOrgData.encodedId}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Collapse in={!collapseSettings}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Grid item>
                    <EditTextInput
                      text={title}
                      setText={setTitle}
                      label={translationsStoryline.title[lang]}
                      required
                      labelDescription={translationsStoryline.titleTooltipVideoFunnel[lang]}
                    />
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <SimpleDivider />
                  </Grid>
                  <Grid item>
                    <StorylineLinkHandler
                      basicOrgData={basicOrgData}
                      orgUrlsJsonb={location?.state?.urlsJsonb ?? []}
                      otherStorylinesUrls={otherStorylinesUrls}
                      theme={theme}
                      urlsJsonb={urlsJsonb?.map((o, index) => ({ ...o, index }))}
                      setUrlsJsonb={setUrlsJsonb}
                    />
                  </Grid>
                  <Grid item sx={{ width: '100%', marginBottom: '0px' }} />
                </Grid>
              </Collapse>
            </Grid>

            <Grid item style={{ width: '100%' }}>
              <Box style={{ minHeight: '20px', position: 'relative' }}>
                <SimpleDivider />
                <Box
                  style={{
                    position: 'absolute',
                    marginBottom: '0px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    left: '50%',
                    top: '-18px',
                    transform: 'translate(-75%,0%)',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Tooltip
                    title={
                      collapseSettings
                        ? translationsStoryline.videoFunnel.tooltips.expandSettings[lang]
                        : translationsStoryline.videoFunnel.tooltips.collapseSettings[lang]
                    }
                  >
                    <IconButton
                      onClick={() => setCollapseSettings(!collapseSettings)}
                      sx={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        background: 'white',
                        padding: '0px',
                        width: '40px',
                        height: '40px',
                        '&:hover': {
                          background: '#ddd',
                          color: '#666',
                        },
                      }}
                    >
                      {collapseSettings ? (
                        <ExpandMoreRoundedIcon fontSize="large" sx={{ padding: '0px' }} />
                      ) : (
                        <ExpandLessRoundedIcon fontSize="large" sx={{ padding: '0px' }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            ...(isFullscreen
              ? {
                  background: 'white',
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  transition: 'all 1s ease-in-out 1s',
                  zIndex: '3',
                }
              : { background: 'white', transition: 'all 1s ease-in-out 1s;', height: '100%' }),
            transition: 'all 1s ease-in-out 1s',
          }}
        >
          <VideoFunnelReactFlow
            basicOrgData={basicOrgData}
            nodes={nodes}
            setNodes={setNodes}
            edges={edges}
            setEdges={setEdges}
            setReactFlowInstance={setReactFlowInstance}
            isFullscreen={isFullscreen}
            setIsFullscreen={setIsFullscreen}
            selection={selection}
            setSelection={setSelection}
          />
          <Fade
            in={selection?.edges?.length > 0 || selection?.nodes?.length > 0}
            timeout={selection?.edges?.length > 0 || selection?.nodes?.length > 0 ? 500 : 0}
          >
            <Box
              className="videoFunnel-flow-remove-item"
              style={{ bottom: isFullscreen ? '16px' : '72px' }}
            >
              <Collapse
                in={
                  selection?.nodes?.length === 1 &&
                  !(selection?.edges?.length > 0) &&
                  ['videoFunnelAddNode', 'videoFunnelEmptyVideoNode'].includes(
                    selection?.nodes[0].type,
                  )
                }
                timeout={selection?.nodes?.length === 0 || selection?.nodes?.length === 1 ? 0 : 350}
                orientation="horizontal"
                style={{ maxHeight: selection?.nodes?.length === 1 ? 'auto' : '36px' }}
              >
                {selection?.nodes?.length && (
                  <SplitButton
                    variant="contained"
                    color="primary"
                    options={addVideoButtonOptions}
                    menuPlacement="top"
                    handleClick={handleClickSplitButtonAddVideo}
                    asFab
                  />
                )}
              </Collapse>

              <Fab
                variant="extended"
                color="primary"
                onClick={removeSelection}
                sx={{ width: 'auto' }}
              >
                {translationsStoryline.videoFunnel.removeObjects.removeConnectionAndItem[lang]({
                  itemCount: selection?.nodes?.length,
                  connectionCount: selection?.edges?.length,
                })}
              </Fab>
            </Box>
          </Fade>
        </Box>
        <Collapse in={!isFullscreen} timeout="auto" unmountOnExit>
          <SettingsFooter
            itemStatus={status || storylineStatuses.EDIT}
            save={handleSave}
            canActivate={canActivate()}
            canSave={canSave()}
            onClickDelete={handleDelete}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            disabledLive={numLiveStories >= freemiumStoriesLimit?.live}
          />
        </Collapse>
      </Box>
      <VideoFunnelAddVideoDialog
        open={openAddVideoEmpty}
        handleClose={handleOnAddVideoSelection}
        basicOrgData={basicOrgData}
        maxVideos={1}
      />
      {openAddEndScreen && orgHasEndScreen && (
        <VideoFunnelAddEndScreenDialog
          open={openAddEndScreen}
          handleClose={handleCloseAddEndScreen}
          onConfirm={() => {}}
          basicOrgData={basicOrgData}
          dialogTitle={translationsStoryline.videoFunnel.addEndScreen[lang]}
        />
      )}
      <AlertDialog
        open={simpleAlertDialogDeleteOpen}
        title={translationsStoryline.videoFunnel.deleteFunction.deleteItem[lang]}
        description={translationsStoryline.videoFunnel.deleteFunction.areYouSureDelete[lang]}
        confirmBtnText={translationsStoryline.videoFunnel.deleteFunction.deleteConfirm[lang]}
        cancelBtnText={translationsStoryline.videoFunnel.deleteFunction.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogDeleteOpen(false);
          deleteItem();
        }}
        onCancel={() => {
          setSimpleAlertDialogDeleteOpen(false);
        }}
      />
      <AlertDialog
        open={simpleAlertDialogOpen}
        title={translations.videoCard.closeBeforeSave[lang]}
        description={translations.videoCard.areYouSureClose[lang]}
        confirmBtnText={translations.videoCard.confirm[lang]}
        cancelBtnText={translations.videoCard.noCancel[lang]}
        onConfirm={() => {
          setSimpleAlertDialogOpen(false);
          handleNavigateBack({});
        }}
        onCancel={() => {
          setSimpleAlertDialogOpen(false);
        }}
      />
    </>
  );
}

// freemiumSettings.ts

import { PremiumFeatures } from 'src/components/admin/EditPremiumFeaturesView/types';

export interface FreemiumTypeSettings {
  live?: number;
  total?: number;
  videoCards?: number;
  connectionsPerCard?: number;
  whenCondition?: number;
  excludeWhenCondition?: number;
  conditions?: string[];
}

export interface FreemiumFeatures {
  videoFunnel: FreemiumTypeSettings;
  // storyline?: FreemiumTypeSettings; // Commented as per original code
  stories: FreemiumTypeSettings;
  videoCard: FreemiumTypeSettings;
  // videoRequest?: FreemiumTypeSettings; // Commented as per original code
}

export type PackageVariant = 'none' | 'freemium' | undefined;

export const packageVariant = {
  freemium: 'freemium',
} as const;

export const freemiumDefaultPackageSettings: PremiumFeatures = {
  interactionOption: true,
  textMessage: true,
  subtitleAllowed: false,
  includeSubtitle: false,
  editableConsentTexts: false,
  autoReminders: true,
  autoRequests: false,
  excludeFromVimeo: true,
  hideWatermark: false,
  advancedWidgetStyling: false,
  advancedWidgetBehavior: false,
  requestsLibraryBeta: false,
  orgSubtitleUpload: true,
  advancedWidgetStylingMobile: false,
  widgetThree: true,
  videoFunnel: true,
  videoFunnelEndScreen: true,
  widgetVersion: 'default',
  packageVariant: packageVariant.freemium,
  freemiumFeatures: {
    videoFunnel: {
      // live: 1,
      // total: 3,
      // videoCards: 3,
      connectionsPerCard: 2,
    },
    // storyline: { live: 1, total: 3, videoCards: 3 }, // Commented as per original code
    stories: {
      live: 2,
      // total: 6,
      whenCondition: 1,
      conditions: ['exactMatch'],
      excludeWhenCondition: 1,
    },
    videoCard: {
      live: 3,
    },
    // videoRequest: { total: 24 }, // Commented as per original code
  },
  advStyleVidTitleDialog: false,
  videoCollector: false,
  organizationPermissionsSettings: false,
  videoRequestEmailCustomization: false,
  conversationalAvatar: false,
  advStyleStories: false,
  userLimitPerOrganization: 0,
};

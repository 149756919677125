import {
  Checkbox,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { freemiumDefaultPackageSettings, FreemiumFeatures } from 'src/utils/freemiumSettings';
import { PremiumFeatures } from './types';
import FreemiumFeaturesCollapse from './FreemiumFeaturesCollapse';
import { inputGroups } from './inputGroups';

interface RenderInputFieldProps {
  componentKey: keyof PremiumFeatures;
  label: string;
  defaultValue: string | boolean | number;
  premiumFeatures: PremiumFeatures;
  handleCheckboxChange: (key: keyof PremiumFeatures) => void;
  handleTextFieldChange: (key: keyof PremiumFeatures, value: string) => void;
  handleNumberFieldChange: (key: keyof PremiumFeatures, value: number) => void;
  handleFreemiumFeaturesChange: (
    key: keyof PremiumFeatures,
    value: FreemiumFeatures | undefined,
  ) => void;
  handleFeaturesUpdate: (features: PremiumFeatures) => void;
  createNewOrg: boolean;
  description?: string;
  disabled: boolean;
  dependsOn?: Array<{ key: keyof PremiumFeatures; value: boolean | string | number }>;
  users: [{ id: number }];
}

function RenderInputField({
  componentKey,
  label,
  defaultValue,
  premiumFeatures,
  handleCheckboxChange,
  handleTextFieldChange,
  handleNumberFieldChange,
  handleFreemiumFeaturesChange,
  createNewOrg,
  description = '',
  disabled,
  dependsOn = undefined,
  handleFeaturesUpdate,
  users,
}: RenderInputFieldProps) {
  const isString = typeof defaultValue === 'string';
  const isNumber = typeof defaultValue === 'number';
  const isPackageVariant = componentKey === 'packageVariant';

  const findLabelForKey = (key: keyof PremiumFeatures) => {
    const foundInput = inputGroups
      .flatMap((group) => group.inputs)
      .find((input) => input.key === key);

    return foundInput ? foundInput.label : key;
  };

  const unmetDependencies = dependsOn
    ? dependsOn.filter((dep) => premiumFeatures[dep.key] !== dep.value)
    : [];

  const areDependenciesMet = unmetDependencies.length === 0;
  const finalDisabled = disabled || !areDependenciesMet;

  const unmetDependenciesMessage = unmetDependencies
    .map((dep) => `Requires ${findLabelForKey(dep.key)} to be ${dep.value}`)
    .join(', <br />');

  let inputElement;
  if (isPackageVariant) {
    inputElement = (
      <Stack spacing={0}>
        <Typography variant="titleSmall">{label}</Typography>
        <Select
          value={premiumFeatures[componentKey] as string}
          onChange={(e) => {
            if (e.target.value === 'freemium') {
              handleFreemiumFeaturesChange(
                'freemiumFeatures',
                freemiumDefaultPackageSettings.freemiumFeatures,
              );
              handleFeaturesUpdate(freemiumDefaultPackageSettings);
            } else if (e.target.value === 'none') {
              handleFreemiumFeaturesChange('freemiumFeatures', undefined);
            }

            handleTextFieldChange(componentKey, e.target.value);
          }}
          size="small"
          disabled={finalDisabled}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="freemium">Freemium</MenuItem>
        </Select>
        <FreemiumFeaturesCollapse
          packageVariant={premiumFeatures[componentKey]}
          freemiumFeatures={premiumFeatures.freemiumFeatures}
          setFreemiumFeatures={(features) => {
            handleFreemiumFeaturesChange('freemiumFeatures', features);
          }}
        />
      </Stack>
    );
  } else if (isString) {
    inputElement = (
      <Stack spacing={0}>
        <Typography variant="titleSmall">{label}</Typography>
        <TextField
          value={premiumFeatures[componentKey] as string}
          onChange={(e) => handleTextFieldChange(componentKey, e.target.value)}
          size="small"
          disabled={finalDisabled}
        />
      </Stack>
    );
  } else if (isNumber) {
    inputElement = (
      <Stack spacing={0}>
        <Typography variant="titleSmall">{label}</Typography>
        <TextField
          value={premiumFeatures[componentKey] as number}
          onChange={(e) => {
            const { value } = e.target;
            const parsedValue = parseInt(value, 10);

            handleNumberFieldChange(componentKey, Math.max(parsedValue, 0));
          }}
          size="small"
          disabled={finalDisabled}
          type="number"
          error={
            componentKey === 'userLimitPerOrganization' &&
            users.length > premiumFeatures[componentKey] &&
            premiumFeatures[componentKey] !== 0
          }
          inputProps={{
            min: 0,
            defaultValue: Number.isNaN(defaultValue) ? 3 : defaultValue,
          }}
        />

        {componentKey === 'userLimitPerOrganization' && (
          <Typography
            variant="bodySmall"
            color={
              users.length > premiumFeatures[componentKey] && premiumFeatures[componentKey] !== 0
                ? 'error'
                : 'textSecondary'
            }
          >
            Current users: {users.length}
          </Typography>
        )}
      </Stack>
    );
  } else {
    inputElement = (
      <Stack direction="row" spacing={1} alignItems="center">
        <Checkbox
          checked={premiumFeatures[componentKey] as boolean}
          onChange={() => handleCheckboxChange(componentKey)}
          disabled={finalDisabled || (componentKey === 'excludeFromVimeo' && createNewOrg)}
          sx={{ p: 0 }}
        />
        <Typography variant="titleSmall">{label}</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Tooltip
        title={unmetDependenciesMessage || ''}
        disableHoverListener={areDependenciesMet}
        arrow
      >
        <FormControlLabel
          control={<Stack spacing={0}>{inputElement}</Stack>}
          label=""
          sx={{ pl: 4, mb: 0, pb: 0 }}
          style={{ paddingBottom: 0, marginBottom: 0 }}
        />
      </Tooltip>
      {description && (
        <Typography variant="bodySmall" color="textSecondary" sx={{ pl: 7 }}>
          {description}
        </Typography>
      )}
    </>
  );
}

export default RenderInputField;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import _ from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useLanguage } from 'src/context/LanguageContext';
import { translationsStoryline } from '../../../utils/translationsStoryline';
import { translations } from '../../../utils/translations';
import StorylineVideoLibrary from './StorylineVideoLibrary';
import FilterDrawer from '../../common/FilterDrawer';
import AlertDialog from '../../common/AlertDialog';
import getTheme from '../../common/SplitButton/SplitButtonThemes';
import StorylineLinkHandler, {
  checkIfUrlWhenConditionIsUsed,
  checkIfUrlWhenConditionIsUsedByThis,
  checkIfHasAnyConditionWhen,
  checkIfHasAnyExactMatchInvalid,
} from './StorylineLinkHandler';
import SettingsFooter from './StorylineSettingsFooter';

import { actions, storylineStatuses, statuses, packageVariant } from '../../../utils/utils';
import { useApiUtilsStoryline } from '../../../utils/api-utils-storyline';
import { getTranslationKey } from './StorylineLinkWhenCondition';
import SimpleDivider from '../../common/SimpleDivider';
import { widgetPath } from '../../../utils/environment';
import StoryAnalytics from '../StoryAnalytics/StoryAnalytics';

export const getUrlsJson = (urlsJsonb) => {
  return urlsJsonb
    ?.filter((obj) => !!obj?.url)
    ?.map((obj, index) =>
      [
        `{`,
        [
          obj?.url ? `url:"${obj?.url}"` : '',
          obj?.srcImage ? `srcImage:"${obj?.srcImage}"` : '',
          obj?.imageUpdatedAt ? `imageUpdatedAt:"${obj?.imageUpdatedAt}"` : '',
          obj?.srcImageFullPage ? `srcImageFullPage:"${obj?.srcImageFullPage}"` : '',
          obj?.conditionWhen ? `conditionWhen:"${obj?.conditionWhen}"` : '',
          obj?.excludedWhen ? `excludedWhen:"${obj?.excludedWhen}"` : '',
          obj?.override &&
          (getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) ===
            obj?.conditionWhen ||
            getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) ===
              obj?.excludedWhen)
            ? `override:${obj?.override}`
            : `index: ${index}`,
        ]
          ?.filter((o) => o !== '')
          ?.join(','),
        `}`,
      ]?.join(''),
    )
    ?.join(',');
};

export const formatUrlsJson = (urlsJsonb) => {
  const removeEmpty = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== ''));

  return urlsJsonb
    ?.filter((obj) => !!obj?.url)
    ?.map((obj, index) => {
      const result = {
        url: obj.url,
        srcImage: obj.srcImage,
        imageUpdatedAt: obj.imageUpdatedAt,
        srcImageFullPage: obj.srcImageFullPage,
        conditionWhen: obj.conditionWhen,
        excludedWhen: obj.excludedWhen,
      };

      const isExactMatch =
        getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) ===
          obj.conditionWhen ||
        getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch) ===
          obj.excludedWhen;

      if (obj.override && isExactMatch) {
        result.override = obj.override;
      } else {
        result.index = index;
      }

      return removeEmpty(result);
    });
};

function StorylineCreateEdit({
  setStorylines,
  setUsers,
  basicOrgData,
  setInfo,
  setUrls,
  orgUrls,
  orgData,
  orgUrlsJsonb,
}) {
  const lang = useLanguage();
  const apiUtilsStoryline = useApiUtilsStoryline();

  const navigate = useNavigate();
  const { storylineObjects, videoFunnelObjects } = useOutletContext();
  const numLiveStories = [
    ...(storylineObjects.current ?? []),
    ...(videoFunnelObjects.current ?? []),
  ].filter((s) => s.status === storylineStatuses.LIVE).length;

  const isFreemium = basicOrgData?.premiumFeatures?.packageVariant === packageVariant.freemium;
  const freemiumStoriesLimit =
    isFreemium && basicOrgData?.premiumFeatures?.freemiumFeatures?.stories;

  const location = useLocation();
  const { state: { storylineData } = {} } = location || {};
  const item = storylineData;
  const [storylineId, setStorylineIdId] = useState((item && item.id) || '');

  const [showPreview, setShowPreview] = useState(false);

  const otherStorylinesUrls = orgUrlsJsonb?.filter(
    (urlObj) => urlObj.storylineId !== item?.id || !item?.id,
  );
  const [storylineTitle, setStorylineTitle] = useState((item && item.title) || '');
  const [excludeWidgetLibraryBtn, setExcludeWidgetLibraryBtn] = useState(
    (item && item.excludeWidgetLibraryBtn && item.excludeWidgetLibraryBtn) || false,
  );

  const [urlsJsonb, setUrlsJsonb] = useState(
    item?.urlsJsonb?.map((u, index) => ({
      ...u,
      index,
      ...(!u?.conditionWhen && !u?.excludedWhen
        ? {
            conditionWhen: getTranslationKey(
              translationsStoryline.availableWhenConditions.exactMatch,
            ),
          }
        : {}),
    })) ??
      item?.urls?.map((u, index) => ({
        url: u,
        conditionWhen: getTranslationKey(translationsStoryline.availableWhenConditions.exactMatch),
        index,
      })) ??
      [],
  );

  const [storylineVideoCards, setStorylineVideoCards] = useState((item && item?.videoCards) || []);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [originalStorylineVideoCards, setOriginalStorylineVideoCards] = useState(
    (item && item.videoCards) || [],
  );
  const [storylineUser, setStorylineUser] = useState((item && item?.userId) || []);
  const [storylineStatus, setStorylineStatus] = useState(
    (item && item.status) || storylineStatuses.EDIT,
  );

  const [videoCards, setVideoCards] = useState(item?.videoCards || []);
  const [videoStatusFilter, setVideoStatusFilter] = useState([
    item && storylineVideoCards?.length > 0 ? statuses.LIVE_SELECTED : statuses.LIVE,
  ]);
  const [tagsToFilter] = useState([]);
  const [tagIdsForFilter, setTagIdsForFilter] = useState([]);
  const [randomizeVideoOrder, setRandomizeVideoOrder] = useState(true);
  const [editableStoryline, setEditableStoryline] = useState(!item);
  const [tagCategories, setTagCategories] = useState([]);
  const [error, setError] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [removeFilter, setRemoveFilter] = useState(null);
  const [selectLocation, setSelectLocation] = React.useState([]);
  const [selectDepartment, setSelectDepartment] = React.useState([]);
  const [selectTheme, setSelectTheme] = React.useState([]);
  const [simpleAlertDialogOpen, setSimpleAlertDialogOpen] = useState(false);
  const [simpleAlertDialogDeleteOpen, setSimpleAlertDialogDeleteOpen] = useState(false);

  const hasChanges = () => {
    if (item) {
      if (
        item.id !== storylineId ||
        item.title !== storylineTitle ||
        item.status !== storylineStatus ||
        !_.isEqual(originalStorylineVideoCards, storylineVideoCards)
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (!item) {
      apiUtilsStoryline
        .createStoryline({
          encodedOrgId: basicOrgData.encodedId,
          status: storylineStatuses.EDIT,
          title: storylineTitle,
          orgId: basicOrgData.id,
          videoCards,
        })
        .then((res) => {
          setStorylineIdId(res.data.createStoryline.storyline.id);
          setStorylineUser(res.data.createStoryline.storyline.userId);
          setUsers(res.data.createStoryline.users);
          setStorylines(res.data.createStoryline.storylines);
          setVideoCards(res.data.createStoryline.videoCards);
          setTagCategories(res.data.createStoryline.tagCategories);
          setStorylineStatus(res.data.createStoryline.storyline.status);
          setUrls(res.data.createStoryline.urls);

          const tags = [];
          const tagIds = [];
          res.data.createStoryline.tagCategories.forEach((cat) => {
            cat.tags.forEach((tag) => {
              tagIds.push(tag.id);
              tags.push({
                id: tag.id,
                selected: false,
              });
            });
          });
        });
    } else if (item?.id && basicOrgData?.encodedId) {
      apiUtilsStoryline
        .getVideoCardsByStorylineId({
          encodedOrgId: basicOrgData.encodedId,
          storylineId: item.id,
          statuses: [],
        })
        .then((res) => {
          const resultData = res.data.videoCardsByStorylineIdAndTags;
          setOriginalStorylineVideoCards([
            ...(resultData?.videoCards || []),
            ...(resultData?.videoRequests || []),
          ]);
          if (resultData?.randomizedVideoCards) {
            const shuffledOrder = _.shuffle([
              ...(resultData?.videoCards || []),
              ...(resultData?.videoRequests || []),
            ]);

            setStorylineVideoCards(shuffledOrder);
            setVideoCards(shuffledOrder);
          } else {
            const sorted = [
              ...(resultData?.videoCards || []),
              ...(resultData?.videoRequests || []),
            ]?.sort((a, b) => {
              return a.index - b.index;
            });

            setStorylineVideoCards(sorted);
            setVideoCards(sorted);
          }

          setTagCategories(resultData?.tagCategories || []);
          setRandomizeVideoOrder(resultData?.randomizedVideoCards ?? true);
          if (
            [...(resultData?.videoCards || []), ...(resultData?.videoRequests || [])]?.some(
              (st) => st.status !== statuses.LIVE,
            )
          ) {
            setVideoStatusFilter([statuses.LIVE_SELECTED]);
          }

          const tags = [];
          const tagIds = [];
          resultData.tagCategories.forEach((cat) => {
            cat.tags.forEach((tag) => {
              tagIds.push(tag.id);
              tags.push({
                id: tag.id,
                selected: false,
              });
            });
          });
        });
    }
  }, [apiUtilsStoryline]);

  useEffect(() => {
    setError(null);
    if (tagsToFilter.length) {
      filterByTags({});
    }
  }, [tagIdsForFilter, videoStatusFilter]);

  useEffect(() => {}, [
    storylineId,
    storylineTitle,
    storylineVideoCards,
    videoCards,
    tagsToFilter,
    tagIdsForFilter,
    tagCategories,
    randomizeVideoOrder,
    videoStatusFilter,
  ]);

  const openWidget = () => {
    if (basicOrgData.encodedId) {
      const initScript = document.createElement('script');
      const inlineScript = document.createTextNode(`
          (function () {
            const settings = {
            id: '${basicOrgData.encodedId}',
            previewMode: ${true},
            previewVideoCardIds: ${JSON.stringify(storylineVideoCards?.flatMap((vc) => vc.id))},
            randomizeVideoOrder: ${randomizeVideoOrder},
          }
          WidgetWrapper.mount(settings)
          }())
          `);

      initScript.appendChild(inlineScript);
      initScript.async = true;

      document.body.appendChild(initScript);

      return () => {
        document.body.removeChild(initScript);
      };
    }
    return () => {};
  };

  useEffect(() => {
    if (showPreview) {
      const script = document.createElement('script');
      script.src = widgetPath;
      script.async = true;
      script.onload = function () {
        openWidget();
      };

      document.body.appendChild(script);
      return () => {
        const allSuspects = document.body.getElementsByTagName('script');

        for (let i = allSuspects?.length; i >= 0; i -= 1) {
          if (
            allSuspects[i]?.src?.includes(widgetPath) ||
            allSuspects[i]?.src?.includes('/widget.2.0.0') ||
            allSuspects[i]?.src?.includes('/widget.3.0.0') ||
            allSuspects[i]?.innerHTML?.includes('WidgetWrapper.mount(settings)') ||
            allSuspects[i]?.innerText?.includes('WidgetWrapper.mount(settings)')
          ) {
            allSuspects[i]?.parentNode?.removeChild(allSuspects[i]);
          }
        }

        const elem = document?.getElementById('owli-widget');

        return elem?.parentNode?.removeChild(elem);
      };
    }
    try {
      window?.LIWidgetWrapper?.unmount();
      // eslint-disable-next-line no-empty
    } catch (e) {}
    const allSuspects = document.body.getElementsByTagName('script');

    for (let i = allSuspects?.length; i >= 0; i -= 1) {
      if (
        allSuspects[i]?.src?.includes(widgetPath) ||
        allSuspects[i]?.src?.includes('/widget.2.0.0') ||
        allSuspects[i]?.src?.includes('/widget.3.0.0') ||
        allSuspects[i]?.innerHTML?.includes('WidgetWrapper.mount(settings)') ||
        allSuspects[i]?.innerText?.includes('WidgetWrapper.mount(settings)')
      ) {
        allSuspects[i]?.parentNode?.removeChild(allSuspects[i]);
      }
    }
    const elem = document?.getElementById('owli-widget');

    return elem?.parentNode?.removeChild(elem);
  }, [showPreview]);

  const deleteItem = () => {
    if (storylineId) {
      apiUtilsStoryline
        .deleteStoryline({
          encodedOrgId: basicOrgData.encodedId,
          storylineId,
          orgId: basicOrgData.id,
        })
        .then((res) => {
          setStorylines(res.data.deleteStoryline.storylines);
          setUsers(res.data.deleteStoryline.users);
          setUrls(res.data.deleteStoryline.urls);
          handleNavigateBack({ succesMsg: translationsStoryline.successFeedback.deleted[lang] });
        })
        .catch((e) => {
          setError(e.message);
        });
    } else {
      handleNavigateBack({ succesMsg: translationsStoryline.successFeedback.deleted[lang] });
    }
  };

  const beforeClose = () => {
    if (hasChanges() || (!item && storylineId)) {
      setSimpleAlertDialogOpen(true);
    } else {
      handleNavigateBack({});
    }
  };

  const errors = {
    NO_TITLE: 'NO_TITLE',
    NO_URL: 'NO_URL',
    URL_USED: 'URL_USED',
    INVALID_URL: 'INVALID_URL',
    WHEN_CONDITION_USED_LONG: 'WHEN_CONDITION_USED_LONG',
    WHEN_CONDITION_USED_BY_OTHER: 'WHEN_CONDITION_USED_BY_OTHER',
    WHEN_CONDITION_MUST_HAVE_VALUE: 'WHEN_CONDITION_MUST_HAVE_VALUE',
    SORRY_ABOUT_INVALID_LINK: 'SORRY_ABOUT_INVALID_LINK',
  };

  const validationError = (newStatus) => {
    if (!storylineTitle) {
      return errors.NO_TITLE;
    }

    if (checkIfUrlWhenConditionIsUsed({ urlsJsonb, otherStorylinesUrls })) {
      return errors.WHEN_CONDITION_USED_BY_OTHER;
    }
    if (checkIfUrlWhenConditionIsUsedByThis({ urlsJsonb, otherStorylinesUrls })) {
      return errors.WHEN_CONDITION_USED_LONG;
    }
    if (!checkIfHasAnyConditionWhen({ urlsJsonb }) && newStatus === storylineStatuses.LIVE) {
      return errors.WHEN_CONDITION_MUST_HAVE_VALUE;
    }
    if (checkIfHasAnyExactMatchInvalid({ urlsJsonb })) {
      return errors.SORRY_ABOUT_INVALID_LINK;
    }
    return false;
  };

  const getVideoCards = () => {
    return _.map(storylineVideoCards, (video, idx) => {
      const index = randomizeVideoOrder ? -1 : idx;
      if (video.type === 'video_request') {
        return { videoRequestId: video.id, index };
      }
      return { videoCardId: video.id, index };
    });
  };

  const getItemsStatus = () => {
    return item ? item.status : storylineStatuses.EDIT;
  };

  const saveOrUpdate = async (newStatus = storylineStatuses.EDIT) => {
    if (validationError(newStatus)) {
      throw new Error(validationError(newStatus));
    }
    return apiUtilsStoryline
      .updateStorylineBeta({
        encodedOrgId: basicOrgData.encodedId,
        orgId: basicOrgData.id,
        id: storylineId,
        user: storylineUser,
        status: newStatus,
        title: storylineTitle,
        videoCards: getVideoCards(),
        excludeWidgetLibraryBtn,
        urlsJsonb,
      })
      .then((res) => {
        const result = res.data.updateStorylineBeta;
        setUsers(result.users);
        setStorylines(result.storylines);
        setUrls(result.urls);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error('Unexpected error occurred');
      });
  };

  const save = async () => {
    if (
      storylineStatuses.LIVE === storylineStatus &&
      storylineVideoCards?.every((video) => video.status !== statuses.LIVE)
    ) {
      setError(translationsStoryline.errors.NO_LIVE_VIDEOS_ACTIVE[lang]);
    } else {
      await saveOrUpdate(storylineStatus)
        .then(() => {
          if (item) {
            handleNavigateBack({ succesMsg: translationsStoryline.successFeedback.updated[lang] });
          } else {
            handleNavigateBack({ succesMsg: translationsStoryline.successFeedback.created[lang] });
          }
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  const saveAndNext = (action) => {
    switch (action) {
      case actions.PUBLISH:
        if (storylineVideoCards?.every((video) => video.status !== statuses.LIVE)) {
          setError(translationsStoryline.errors.ACTIVATE_STORYLINE_WITH_LIVE_VIDEOS[lang]);
        } else {
          saveOrUpdate(storylineStatuses.LIVE)
            .then(() => {
              setInfo(translationsStoryline.successFeedback.LIVE[lang]);
              handleNavigateBack({ succesMsg: translationsStoryline.successFeedback.LIVE[lang] });
            })
            .catch((e) => {
              setError(e.message);
            });
        }
        break;
      case actions.UNPUBLISH:
        saveOrUpdate(storylineStatuses.EDIT)
          .then(() => {
            handleNavigateBack({
              succesMsg: translationsStoryline.successFeedback.deactivate[lang],
            });
          })
          .catch((e) => {
            setError(e.message);
          });
        break;
      default:
    }
  };

  const deleteItemConfirm = () => {
    setSimpleAlertDialogDeleteOpen(true);
  };

  const validateTitle = (value) => {
    if (value.length > 65) {
      setError(translationsStoryline.errors.TITLE_TEXT_TOO_LONG[lang]);
    } else {
      setError(null);
      setStorylineTitle(value);
    }
  };
  const getVideoStatusFilter = (statusesFilter = videoStatusFilter) => {
    if (!statusesFilter || statusesFilter?.length === 0) {
      return Object.entries(statuses)?.map(([key, value]) => value) ?? [];
    }

    return [
      ...new Set(
        statusesFilter.map((vsf) => {
          if (vsf === statuses.LIVE_SELECTED) {
            return statuses.LIVE;
          }
          return vsf;
        }),
      ),
    ];
  };

  const filterByTags = ({
    tags = tagIdsForFilter,
    storylineItems = storylineVideoCards,
    statusesToFilter = videoStatusFilter,
  }) => {
    if (tags?.length > 0) {
      apiUtilsStoryline
        .getVideosByTags(basicOrgData.encodedId, tags, [...getVideoStatusFilter(statusesToFilter)])
        .then((result) => {
          const resultItems = [
            ...(result?.data?.videoCardsByTagsWithStatus?.videoCards ?? []),
            ...(result?.data?.videoCardsByTagsWithStatus?.videoRequests ?? []),
          ];

          if (resultItems?.length === 0) {
            setVideoCards([]);
          } else {
            const liveSelected =
              statusesToFilter?.some((vsf) => vsf === statuses.LIVE_SELECTED) === true
                ? storylineItems?.filter((vc) =>
                    resultItems?.every((itemVc) => itemVc.id !== vc.id),
                  ) ?? []
                : [];
            const items = [...resultItems, ...liveSelected];

            items?.sort((a, b) => {
              const indexA = _.findIndex(storylineItems, { id: a.id });

              const indexB = _.findIndex(storylineItems, { id: b.id });

              return (indexA >= 0 ? indexA : items.length) - (indexB >= 0 ? indexB : items.length);
            });
            setVideoCards([...items]);
          }
        })
        .catch((error) => {});
    } else {
      apiUtilsStoryline
        .getAllVideoCardsByOrgId(basicOrgData.encodedId, [
          ...getVideoStatusFilter(statusesToFilter),
        ])
        .then((result) => {
          const resultItems = [
            ...(result?.data?.allLiveVideoCardsByOrgIdByStatus?.videoCards ?? []),
            ...(result?.data?.allLiveVideoCardsByOrgIdByStatus?.videoRequests ?? []),
          ];

          const liveSelected =
            statusesToFilter?.some((vsf) => vsf === statuses.LIVE_SELECTED) === true
              ? storylineItems?.filter((vc) =>
                  resultItems?.every((itemVc) => itemVc.id !== vc.id),
                ) ?? []
              : [];
          const items = [...liveSelected, ...resultItems];

          items?.sort((a, b) => {
            const indexA = _.findIndex(storylineItems, { id: a.id });
            const indexB = _.findIndex(storylineItems, { id: b.id });
            return (indexA >= 0 ? indexA : items.length) - (indexB >= 0 ? indexB : items.length);
          });
          setVideoCards([...items]);
        });
    }
  };

  const handleRandomVideoOrder = () => {
    setRandomizeVideoOrder(!randomizeVideoOrder);
  };

  const handleEditStoryline = () => {
    const items = [...storylineVideoCards];
    if (editableStoryline) {
      items.sort((a, b) => {
        return _.findIndex(videoCards, { id: a.id }) - _.findIndex(videoCards, { id: b.id });
      });
      setStorylineVideoCards([...items]);
    }
    filterByTags({
      storylineItems: items,
    });
    setEditableStoryline(!editableStoryline);
  };

  const handleSelected = (e) => {
    const indexOfItem = _.findIndex(storylineVideoCards, { id: e.id });

    if (indexOfItem >= 0) {
      const items = storylineVideoCards;
      items.splice(indexOfItem, 1);

      setStorylineVideoCards([...items]);
    } else {
      setStorylineVideoCards([...storylineVideoCards, e]);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVideoStatusFilter(value);
    filterByTags(null, storylineVideoCards, value);
  };
  const theme = getTheme('active');

  const moveCard = useCallback(
    (sourceId, destinationId) => {
      const sourceIndexVC = _.findIndex(videoCards, { id: sourceId });
      const destinationIndexVC = _.findIndex(videoCards, { id: destinationId });

      const items = [...videoCards];
      const itemToMove = items.splice(sourceIndexVC, 1)[0];
      items.splice(destinationIndexVC, 0, itemToMove);
      if (sourceId === -1 || destinationId === -1) {
        return;
      }
      setVideoCards([...items]);

      const sourceIndex = _.findIndex(storylineVideoCards, { id: sourceId });
      const destinationIndex = _.findIndex(storylineVideoCards, { id: destinationId });
      if (sourceIndex >= 0 && destinationIndex >= 0) {
        const items = [...storylineVideoCards];
        const itemToMove = items.splice(sourceIndex, 1)[0];
        items.splice(destinationIndex, 0, itemToMove);
        if (sourceId === -1 || destinationId === -1) {
          return;
        }
        setStorylineVideoCards([...items]);
      }
    },
    [videoCards, storylineVideoCards],
  );

  document.onkeydown = (event) => {
    const evt = event || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }

    if (isEscape) {
      beforeClose();
    }
  };

  const handleSelectAll = () => {
    if (!isCheckAll) {
      setStorylineVideoCards(_.uniqBy([...videoCards, ...storylineVideoCards], 'id'));
    } else {
      const newArray = storylineVideoCards.filter((vc) => {
        return !videoCards.some((v) => {
          return v.id === vc.id;
        });
      });
      setStorylineVideoCards(newArray);
    }

    setIsCheckAll(!isCheckAll);
  };

  const handleNavigateBack = ({ succesMsg }) => {
    navigate(`/${basicOrgData.orgNameSlug}/backoffice/stories`, {
      state: { ...(succesMsg ? { succesMsg, storylineData: item } : {}) },
    });
  };
  const handleDrawerToggle = (status) => {
    setIsDrawerOpen(status);
    setDrawer({ ...drawer, right: status });
  };

  const handleUpdateFilterState = (type, data) => {
    const {
      tagsToFilter: _tagsToFilter,
      location,
      department,
      theme,
      videoStatusFilter = [],
    } = data;
    setRemoveFilter(null);
    switch (type) {
      case 'TAGS_TO_FILTER':
        setTagIdsForFilter(_tagsToFilter);
        setVideoStatusFilter(videoStatusFilter);
        filterByTags({
          tags: _tagsToFilter,
          statusesToFilter: videoStatusFilter,
        });

        break;
      case 'LOC_DEP_THE':
        setSelectLocation(location);
        setSelectDepartment(department);
        setSelectTheme(theme);

        break;
      case 'LOCATION':
        setSelectLocation(location);

        break;
      case 'DEPARTMENT':
        setSelectDepartment(department);

        break;
      case 'THEME':
        setSelectTheme(theme);

        break;
      default:
        break;
    }
  };

  return (
    <div
      className="edit-storyline-wrap"
      // onClick={beforeClose}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: '3' }}
        open={showPreview}
        onClick={() => setShowPreview(!showPreview)}
      />
      <div
        className="modal-1 video-settings"
        role="main"
        tabIndex="0"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header-1">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
            <Typography variant="headlineMedium">
              <IconButton onClick={beforeClose}>
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              {item
                ? `${translationsStoryline.editStoryline[lang]}`
                : `${translationsStoryline.createNewStoryline[lang]}`}
            </Typography>
            {storylineId && (
              <StoryAnalytics encodedOrgId={basicOrgData.encodedId} identifier={{ storylineId }} />
            )}
          </Stack>
        </div>
        <div className="modal-content-1">
          <div className="texts">
            {error ? (
              <div className="validation-error">
                {error in translationsStoryline.errors
                  ? translationsStoryline.errors[error][lang]
                  : error}
              </div>
            ) : null}
            <fieldset className="section">
              <div style={{ display: 'flex', width: '40%' }}>
                <label htmlFor="title" style={{ paddingRight: '10px' }}>
                  <Tooltip title={translationsStoryline.titleInternalName[lang]}>
                    <Typography variant="titleMedium" className="required-field">
                      {translationsStoryline.title[lang]}
                    </Typography>
                  </Tooltip>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={(e) => validateTitle(e.target.value)}
                    value={storylineTitle}
                    required
                  />
                </label>
              </div>

              <label className="checkbox-label">
                <Tooltip title={translationsStoryline.excludeWidgetLibraryBtnTooltip[lang]}>
                  <input
                    type="checkbox"
                    checked={excludeWidgetLibraryBtn}
                    onChange={() => setExcludeWidgetLibraryBtn(!excludeWidgetLibraryBtn)}
                  />
                </Tooltip>

                <Typography variant="bodyMedium">
                  {translationsStoryline.excludeWidgetLibraryBtn[lang]}
                </Typography>
              </label>
            </fieldset>
            <StorylineLinkHandler
              basicOrgData={basicOrgData}
              setInfo={setInfo}
              setUrls={setUrls}
              orgUrls={orgUrls}
              orgUrlsJsonb={orgUrlsJsonb}
              otherStorylinesUrls={otherStorylinesUrls}
              theme={theme}
              urlsJsonb={urlsJsonb?.map((o, index) => ({ ...o, index }))}
              setUrlsJsonb={setUrlsJsonb}
            />
            <SimpleDivider style={{ marginTop: '16px' }} />
            <fieldset className="section">
              <div>
                <legend className="section-legend">
                  {translationsStoryline.fieldsetLegendVideos[lang]}
                </legend>
                <div className="board-header">
                  <div>
                    <Typography className="required-field" variant="titleMedium">
                      {translationsStoryline.videos[lang]}
                    </Typography>
                  </div>
                  <div className="board-header-filters">
                    {editableStoryline && (
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            disableElevation
                            startIcon={<FilterAltOutlinedIcon />}
                            onClick={() => handleDrawerToggle(true)}
                          >
                            {translations.filter[lang]}
                          </Button>
                        </div>
                        <React.Fragment key="right">
                          <FilterDrawer
                            anchor="right"
                            isOpen={isDrawerOpen}
                            onClose={() => handleDrawerToggle(false)}
                            orgData={{
                              encodedId: basicOrgData.encodedId,
                              id: basicOrgData.id,
                              tagCategories,
                            }}
                            handleUpdateFilterState={handleUpdateFilterState}
                            drawerType="storylineCreateEdit"
                            incStatusFilter={videoStatusFilter}
                            incSelectLocation={selectLocation}
                            incSelectDepartment={selectDepartment}
                            incSelectTheme={selectTheme}
                            incTagsToFilter={tagsToFilter}
                          />
                        </React.Fragment>
                      </div>
                    )}
                  </div>
                </div>
                <div className="select-all-wrapper">
                  {editableStoryline && (
                    <div>
                      <label htmlFor="selectAll">
                        <input
                          type="checkbox"
                          id="selectAll"
                          name="selectAll"
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                        <Typography variant="titleSmall">
                          {translationsStoryline.addAllVideos[lang]}
                        </Typography>
                      </label>
                      <div />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div>
                  <StorylineVideoLibrary
                    editableStoryline={editableStoryline}
                    storylineVideoCards={storylineVideoCards}
                    storylineCards={videoCards?.filter((vc) => {
                      if (editableStoryline) {
                        return true;
                      }
                      const index = _.findIndex(storylineVideoCards, { id: vc.id });
                      return index > -1;
                    })}
                    basicOrgData={basicOrgData}
                    randomizeVideoOrder={randomizeVideoOrder}
                    handleSelected={(e) => {
                      if (editableStoryline) {
                        handleSelected(e);
                      }
                    }}
                    moveCard={(sourceId, destinationId) => {
                      moveCard(sourceId, destinationId);
                    }}
                    theme={theme}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <SettingsFooter
          itemStatus={getItemsStatus()}
          save={save}
          saveAndNext={saveAndNext}
          storylineVideoCards={storylineVideoCards}
          deleteItem={deleteItemConfirm}
          handleRandomVideoOrder={handleRandomVideoOrder}
          randomizeVideoOrder={randomizeVideoOrder}
          handleEditStoryline={handleEditStoryline}
          editableStoryline={editableStoryline}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          handleDeleteClicked={setSimpleAlertDialogDeleteOpen}
          disabledLive={numLiveStories >= freemiumStoriesLimit?.live}
        />

        <AlertDialog
          open={simpleAlertDialogDeleteOpen}
          title={translations.videoCard.deleteItem[lang]}
          description={translations.videoCard.areYouSureDelete[lang]}
          confirmBtnText={translations.videoCard.deleteConfirm[lang]}
          cancelBtnText={translations.videoCard.noCancel[lang]}
          onConfirm={() => {
            setSimpleAlertDialogDeleteOpen(false);
            deleteItem();
          }}
          onCancel={() => {
            setSimpleAlertDialogDeleteOpen(false);
          }}
        />

        <AlertDialog
          open={simpleAlertDialogOpen}
          title={translations.videoCard.closeBeforeSave[lang]}
          description={translations.videoCard.areYouSureClose[lang]}
          confirmBtnText={translations.videoCard.confirm[lang]}
          cancelBtnText={translations.videoCard.noCancel[lang]}
          onConfirm={() => {
            setSimpleAlertDialogOpen(false);
            handleNavigateBack({});
          }}
          onCancel={() => {
            setSimpleAlertDialogOpen(false);
          }}
        />
      </div>
    </div>
  );
}

export default StorylineCreateEdit;
